export const messages = {
  toast: {
    logout: "Logout Successfully!",
    login: "Welcome Back!",
    nodata: "Data not found",
    signUp: "Sign Up Successfully!",
  },
  errors: {
    user_req: "Username is required!",
    pass_req: "Password is required!",
  },
};
