import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { messages } from "../../helpers/messages";
import { apiendpoints } from "../../helpers/constants";
import { toast } from "react-toastify";

// Request to user login
export const reqToUserLogin = createAsyncThunk(
  "reqToUserLogin",
  async ({ data }) => {
    try {
      const response = await Axios.post(apiendpoints.login, data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
// Request to user login
export const reqToGetUserDetailById = createAsyncThunk(
  "reqToGetUserDetailById",
  async ({ data }) => {
    try {
      const response = await Axios.get(
        `${apiendpoints.getUsers}` + "/" + `${data?.id || 0}`,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

//Request for Access token for capturing photo
export const reqToGetVerifyToken = createAsyncThunk(
  "reqToGetVerifyToken",
  async () => {
    try {
      const response = await Axios.get(
        `/user/sumsub/generate-access-token`,
        authHeaders()
      );

      return response;
    } catch (error) {
      return error;
    }
  }
);

//Request for OTP for filling details
export const verifyOtp = createAsyncThunk("verifyOtp", async (data) => {
  try {
    const response = await Axios.post(
      `/user/verified-otp`,
      data.data,
      authHeaders()
    );
    if (response.status === 200) {
      if (data.type === "login") {
        notify(messages.toast.login, "success");
      } else {
        notify(messages.toast.signUp, "success");
      }
    }
    return response;
  } catch (error) {
    return error;
  }
});

export const signUpUser = createAsyncThunk("signUpUser", async (data) => {
  try {
    const response = await Axios.post(
      `/user/sign-up`,
      data.data,
      authHeaders()
    );
    if (data.cb) {
      data.cb(response?.data && response.data);
    }
    return response;
  } catch (error) {
    return error;
  }
});

export const sendOtp = createAsyncThunk("sendOtp", async (data) => {
  try {
    const response = await Axios.post(
      `/user/send-otp`,
      data.data,
      authHeaders()
    );

    if (data.cb) {
      data.cb(response?.data && response.data);
    }
    return response;
  } catch (error) {
    return error;
  }
});

export const temporaryLoginForADay = createAsyncThunk(
  "temporaryLoginForADay",
  async (data) => {
    try {
      const response = Axios.post(`/user/login-v2`, data.data, authHeaders());
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialUser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const initialState = {
  loader: false,
  user: initialUser,
  userDetail: {},
  profileToken: "",
  signUp: {},
  error: "",
  otp: {},
};

// Create user slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reqToUserLogout: (state) => {
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      notify(messages.toast.logout, "success");
    },
    forceLogout: (state) => {
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    // User login
    builder.addCase(reqToUserLogin.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToUserLogin.fulfilled, (state, action) => {
      state.loader = false;
      if (action.payload.status === 200 || action.payload.status === 201) {
        state.user = action.payload.data;
        state.userDetail = action.payload.data;
        notify(messages.toast.login, "success");
        const authToken = action.payload.headers["authorization"];
        localStorage.setItem("token", JSON.stringify(authToken));
        localStorage.setItem("user", JSON.stringify(action.payload.data));
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data.message, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToUserLogin.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // Get User Detail List
    builder.addCase(reqToGetUserDetailById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetUserDetailById.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.userDetail = action?.payload?.data?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetUserDetailById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //Get Signup profile Image
    builder.addCase(reqToGetVerifyToken.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetVerifyToken.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.profileToken = action?.payload?.data?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetVerifyToken.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //Verify OTP
    builder.addCase(verifyOtp.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.loader = false;

      if (action.payload.status === 200 || action.payload.status === 201) {
        // state.otp = action?.payload?.data?.data;
        state.user = action.payload.data;
        state.userDetail = action.payload.data;
        // notify(messages.toast.signUp, "success");
        const authToken = action.payload.headers["authorization"];
        localStorage.setItem("token", JSON.stringify(authToken));
        localStorage.setItem("user", JSON.stringify(action.payload.data));
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data.message, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //Sign Up
    builder.addCase(signUpUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(signUpUser.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.signUp = action?.payload?.data?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data.message, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(signUpUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //Sent OTP
    builder.addCase(sendOtp.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(sendOtp.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.signUp = action?.payload?.data?.data;
        notify("Otp has been sent!", "success");
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data.message, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(sendOtp.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //TEMPORARY LOGIN FOR A DAY
    builder.addCase(temporaryLoginForADay.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(temporaryLoginForADay.fulfilled, (state, action) => {
      state.loader = false;

      if (action.payload.status === 200 || action.payload.status === 201) {
        // state.otp = action?.payload?.data?.data;
        state.user = action.payload.data;
        state.userDetail = action.payload.data;
        // notify(messages.toast.signUp, "success");
        const authToken = action.payload.headers["authorization"];
        localStorage.setItem("token", JSON.stringify(authToken));
        localStorage.setItem("user", JSON.stringify(action.payload.data));
        notify("Login Successfully!", "success");
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data.message, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(temporaryLoginForADay.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export const { reqToUserLogout, forceLogout } = userSlice.actions;
export default userSlice.reducer;
