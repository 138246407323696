import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";

// For playGame
export const reqToplayGame = createAsyncThunk("reqToplayGame", async (data) => {
  try {
    const response = await Axios.post(
      apiendpoints.playGame,
      data.data,
      authHeaders()
    );
    if (data.cb) {
      data.cb(response?.data && response.data);
    }
    return response;
  } catch (error) {
    return error;
  }
});

// For GameResult
export const reqForGameResult = createAsyncThunk(
  "reqForGameResult",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.gameResult,
        data.data,
        authHeaders()
      );
      if (data.cb) {
        data.cb(response?.data && response.data);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

// For ExtraWin
export const reqForExtraWin = createAsyncThunk(
  "reqForExtraWin",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.extraWin,
        data.data,
        authHeaders()
      );
      if (data.cb) {
        data.cb(response?.data && response.data);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initialState
const initialState = {
  loader: false,
  error: "",
  playGame: {},
  gameResult: {},
  extraWin: {},
};

// Create slice
const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // play game
    builder.addCase(reqToplayGame.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToplayGame.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.playGame = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToplayGame.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // Game Result
    builder.addCase(reqForGameResult.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqForGameResult.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.gameResult = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqForGameResult.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // req For ExtraWin
    builder.addCase(reqForExtraWin.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqForExtraWin.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.extraWin = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqForExtraWin.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default gameSlice.reducer;
