import React from "react";
import { Button } from "react-bootstrap";
import BenifitCard from "../../../component/Common/BenifitCard";
import { Images } from "../../../images";

const CollectBenifits = ({
  benefitD,
  benefitClaimType,
  collectClaimHandler,
  benefitDueDay,
  nextToCongratsHandler,
}) => {
  function formatMoney(value) {
    if (value < 1000) {
      return value;
    } else {
      return (value / 1000).toFixed(1) + "K";
    }
  }

  const cardObj =
    benefitD.type === "Cash"
      ? benefitClaimType === "collect"
        ? {
            title: `Your P${formatMoney(
              benefitD.amount ? benefitD.amount : 0
              // )} monthly allowance is DUE today. Tap ‘COLLECT’ button to process for delivery.`,
            )} monthly allowance is DUE today. Tap ‘VERIFY’ button to process for delivery.`,
            icon: benefitD.banner_image
              ? benefitD.banner_image
              : Images.thousandPeso,
            mainImg: benefitD.claim_image
              ? benefitD.claim_image
              : Images.monthlyAllowance,
            showCollect: true,
            showDueDay: false,
            dueDays: 0,
            benefitId: benefitD.id,
            fullImg: true,
          }
        : {
            title: `Your next P${formatMoney(
              benefitD.amount ? benefitD.amount : 0
            )} SC Monthly Allowance
        is due in`,
            icon: benefitD.banner_image
              ? benefitD.banner_image
              : Images.thousandPeso,
            mainImg: benefitD.claim_image
              ? benefitD.claim_image
              : Images.monthlyAllowance,
            showCollect: false,
            showDueDay: true,
            dueDays: benefitDueDay,
            benefitId: benefitD.id,
          }
      : benefitClaimType === "collect"
      ? {
          title: `Your Free ${benefitD.type}
          is DUE today. Tap ‘COLLECT’ button to process for delivery.`,
          icon: benefitD.banner_image
            ? benefitD.banner_image
            : Images.thousandPeso,
          mainImg: benefitD.claim_image
            ? benefitD.claim_image
            : Images.monthlyAllowance,
          showCollect: true,
          showDueDay: false,
          dueDays: 0,
          benefitId: benefitD.id,
        }
      : {
          title: `Your next Free ${benefitD.type}
          is due in `,
          icon: benefitD.banner_image
            ? benefitD.banner_image
            : Images.thousandPeso,
          mainImg: benefitD.claim_image
            ? benefitD.claim_image
            : Images.monthlyAllowance,
          showCollect: false,
          showDueDay: true,
          dueDays: benefitDueDay,
          benefitId: benefitD.id,
        };

  return (
    <>
      <div className="benifits_card_wrapper p-3">
        <BenifitCard
          cardObj={cardObj}
          collectClaimHandler={collectClaimHandler}
        />
        <div className="text-center">
          <img
            src={cardObj.mainImg}
            alt="thousandPeso"
            className={`img-fluid w-100 ${!cardObj.fullImg && "card_img"}`}
          />
        </div>
        {benefitClaimType !== "collect" && (
          <div className="text-center">
            <Button
              variant="primary"
              className="tap-btn"
              onClick={() => nextToCongratsHandler("random")}
            >
              TAP
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default CollectBenifits;
