import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  reqToUserLogin,
  sendOtp,
  temporaryLoginForADay,
  verifyOtp,
} from "../../store/slice/userSlice";
import Loader from "../../component/Layout/Loader";
import { routes } from "../../helpers/constants";
import { Images } from "../../images";

import LoginForm from "../../component/Common/LoginComponent/LoginForm";
import VerifyOtp from "../../component/Common/SignupComponent/VerifyOtp";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userReducer = useSelector((state) => state.user);
  const [showLoading, setShowLoading] = useState(true);

  const [loginTab, setLoginTab] = useState("1");

  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState("");

  // Initial login form values
  const [values, setValues] = useState({
    firstName: "",
    // lastName: "",
    countryCode: {
      label: "Philippines",
      value: "+63",
    },
    phoneNumber: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const onchangeHandler = (e, type) => {
    if (type === "dropdown") {
      setValues({
        ...values,
        countryCode: e,
      });
      setErrors({
        ...errors,
        countryCode: "",
      });
    } else if (type === "firstName") {
      setValues({
        ...values,
        firstName: e.target.value,
      });
      setErrors({
        ...errors,
        firstName: "",
      });
      // } else if (type === "lastName") {
      //   setValues({
      //     ...values,
      //     lastName: e.target.value,
      //   });
      //   setErrors({
      //     ...errors,
      //     lastName: "",
      //   });
    } else {
      setValues({
        ...values,
        phoneNumber: e.target.value,
      });
      setErrors({
        ...errors,
        phoneNumber: "",
      });
    }
  };

  const otpChangeHandler = (e) => {
    setOtp(e);
    setOtpErr("");
  };

  const otpValidation = () => {
    if (!otp) {
      setOtpErr("One time passcode is required!");
    } else if (otp.length < 6) {
      setOtpErr("Please Enter 6 digit OTP!");
    } else {
      return true;
    }
  };

  const validate = () => {
    let errors = {};
    if (!values.countryCode) {
      errors = { ...errors, countryCode: "Select country please!" };
    }
    if (!values.firstName?.trim()) {
      errors = { ...errors, firstName: "Please Enter firstName!" };
    }
    // if (!values.lastName?.trim()) {
    //   errors = { ...errors, lastName: "Please Enter lastName!" };
    // }
    if (!values.phoneNumber?.trim()) {
      errors = { ...errors, phoneNumber: "Please Enter mobile number!" };
    }
    setErrors(errors);
    return Object.values(errors).every((x) => x === "");
  };

  // Handle user login with username and password
  const handleSendOtp = (event) => {
    event.preventDefault();
    if (validate()) {
      const data = {
        country_code: values.countryCode?.value,
        phone: values.phoneNumber,
        first_name: values.firstName,
        // last_name: values.lastName,
      };

      dispatch(
        temporaryLoginForADay({
          data,
        })
      );

      // dispatch(
      //   sendOtp({
      //     data: data,
      //     cb: (res) => {
      //       if (res === "Success") {
      //         setLoginTab("2");
      //       }
      //     },
      //   })
      // );
    }
  };

  const handleSubmitOtpBtn = () => {
    if (otpValidation()) {
      dispatch(
        verifyOtp({
          data: {
            phone: values.phoneNumber,
            otp: otp,
          },
          type: "login",
        })
      );
    }
  };

  return (
    <>
      {userReducer.loader && <Loader />}
      <div className="fb_navbar fixed-top">
        <div className="text-decoration-none d-inline-block mx-auto">
          {/* <img
            src={Images.logoSigla}
            alt="logoSigla"
            className=""
            height={30}
          /> */}
        </div>
      </div>
      {showLoading ? (
        <div className="auth-main-content">
          <div className="signup-loader">
            <img
              src={Images.siglaLogo2}
              alt="logoSigla"
              className="img-fluid"
            />
            {/* <h2 className="text-primary ">Senior Citizen App</h2> */}
          </div>
        </div>
      ) : (
        <div className="tc-login-content">
          <div className="container">
            <div className="tc-login-form">
              <div className="tc-login-form-box">
                <Link to={"/"} className="tc-logo">
                  {/* <img src={Logo} alt="LOGO" /> */}
                  <div className="signup-loader">
                    <img
                      src={Images.siglaLogo2}
                      alt="gilasLogoOrange"
                      className="img-fluid"
                    />
                    {/* <h2 className="text-primary">Senior Citizen App</h2> */}
                  </div>
                </Link>
                {/* {loginTab === "1" ? ( */}
                <LoginForm
                  onchangeHandler={onchangeHandler}
                  values={values}
                  errors={errors}
                  handleSendOtp={handleSendOtp}
                />
                {/* ) : ( */}
                {/* <VerifyOtp
                    otpChangeHandler={otpChangeHandler}
                    otp={otp}
                    otpErr={otpErr}
                    handleSubmitOtpBtn={handleSubmitOtpBtn}
                  /> */}
                {/* )} */}
              </div>
              {/* {loginTab === "1" ? (
                <div className="sign-in">
                  <strong>Don't have an account ?</strong>
                  <Link to={routes.signuppage}> Sign Up</Link>
                </div>
              ) : (
                <div className="sign-in">
                  <div role="button" onClick={() => setLoginTab("1")}>
                    {"< back"}
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      )}
      <div className="tc-login-footer-section"></div>
    </>
  );
};

export default LoginPage;
