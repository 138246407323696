import React from "react";
import { Outlet } from "react-router-dom";

const AuthPage = () => {
  return (
    <div className="tc-login-section">
      <Outlet />
    </div>
  );
};

export default AuthPage;
