import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";

// For GetbroadcastList
export const reqToGetbroadcastList = createAsyncThunk(
  "reqToGetbroadcastList",
  async (data) => {
    try {
      const response = await Axios.get(
        apiendpoints.getbroadcastList,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initialState
const initialState = {
  loader: false,
  error: "",
  broadcastList: [],
};

// Create slice
const broadcastSlice = createSlice({
  name: "broadcast",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get broadcast List
    builder.addCase(reqToGetbroadcastList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetbroadcastList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.broadcastList = action?.payload?.data?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetbroadcastList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default broadcastSlice.reducer;
