import React, { useEffect } from "react";
import { Images } from "../../images";

const Preloader = ({ preLoaderImg, onLoadGame, timeoutRef, breakTimeOut }) => {
  useEffect(() => {
    onLoadGame();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [breakTimeOut]);

  return (
    <>
      <div className="preloader_wrapper">
        <div className="flex_wrapper">
          {/* <Loader title="Register now at NCSC" /> */}
          <div>
            {preLoaderImg ? (
              <></>
            ) : (
              // <h2 className="fw-bold mb-4">Register now at NCSC</h2>
              <></>
            )}
            <div className="mb-4">
              <img
                // src={preLoaderImg ? preLoaderImg : Images.ncscLogo}
                src={preLoaderImg ? preLoaderImg : Images.liveBingoPreLoader}
                alt="ncsc"
                className="img-fluid last_prize_img"
              />
            </div>
            <div className="loader_wrapper">
              <p className="loading_text">loading...</p>
              <div className="loading_bar">
                <div className="progress_bar"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preloader;
