import React from "react";
import { Button } from "react-bootstrap";

const LiveGameWon = ({ gameName, afterWonOrTryAgain }) => {
  return (
    <>
      <div className="flex_wrapper">
        <div className="win_wrapper text-center">
          <div>
            <h4 className="fw-bold">{gameName}</h4>
            <div className="win_text">YOU WON!</div>
          </div>
          <div>
            <div className="activity_win_reward">P2,000</div>
          </div>
          <div className="d-flex justify-content-center align-items-center w-100">
            <Button
              variant="primary"
              size="lg"
              onClick={() => afterWonOrTryAgain()}
            >
              DONE
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveGameWon;
