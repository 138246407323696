import React, { useEffect } from "react";
import BroadcastCard from "../../component/Common/BroadcastCard";
import { useDispatch, useSelector } from "react-redux";
import { reqToGetbroadcastList } from "../../store/slice/broadCastSlice";
import Loader from "../../component/Layout/Loader";
import Footer from "../../component/Layout/Footer";
import NoData from "../../component/Common/NoDataFound/NoData";

const Broadcast = () => {
  const dispatch = useDispatch();
  const broadcastReducer = useSelector((state) => state?.broadcast);
  const { broadcastList } = broadcastReducer;

  useEffect(() => {
    dispatch(reqToGetbroadcastList());
  }, []);

  return (
    <>
      <main className="main_content">
        {broadcastReducer.loader && <Loader />}
        <div className="broadcast_wrapper p-3">
          {broadcastList?.length === 0 ? (
            <NoData />
          ) : (
            broadcastList?.map((item, index) => {
              return <BroadcastCard item={item} key={index} />;
            })
          )}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Broadcast;
