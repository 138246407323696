import React from "react";
import { Images } from "../../images";

const LiveGame = () => {
  return (
    <div className="flex_wrapper">
      <img src={Images.bingoLiveImg} alt="bingoLiveImg" />
    </div>
  );
};

export default LiveGame;
