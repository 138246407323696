import React from "react";
import Select from "react-select";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import CountryData from "./countryData.json";

const PhoneNumberForm = ({ values, errors, onChange }) => {
  return (
    <Form>
      <div className="mb-3">
        <Form.Group as={Row} className="border-bottom" controlId="firstName">
          <Form.Label column xs={5} className="p-0 mt-1">
            Name
          </Form.Label>
          <Col xs={7} className="px-0">
            <InputGroup>
              <Form.Control
                type="text"
                className="border-0 shadow-none"
                value={values.firstName}
                onChange={(e) => onChange(e, "firstName")}
              />
            </InputGroup>
          </Col>
        </Form.Group>
        {errors.firstName && <div className="form-err">{errors.firstName}</div>}
      </div>
      {/* <div className="mb-3">
        <Form.Group as={Row} className="border-bottom" controlId="lastName">
          <Form.Label column xs={5} className="p-0 mt-1">
            Last Name
          </Form.Label>
          <Col xs={7} className="px-0">
            <InputGroup>
              <Form.Control
                type="text"
                className="border-0 shadow-none"
                value={values.lastName}
                onChange={(e) => onChange(e, "lastName")}
              />
            </InputGroup>
          </Col>
        </Form.Group>
        {errors.lastName && <div className="form-err">{errors.lastName}</div>}
      </div> */}
      <div className="mb-3">
        <Form.Group as={Row} className="border-bottom" controlId="country">
          <Form.Label column xs={5} className="p-0 mt-1">
            Select Country
          </Form.Label>
          <Col xs={7} className="px-0">
            <Select
              className="react-select-dropdown"
              aria-label="Default select example"
              onChange={(e) => onChange(e, "dropdown")}
              options={CountryData}
              value={values.countryCode}
              placeholder="Open this select menu"
            />
          </Col>
        </Form.Group>
        {errors.countryCode && (
          <div className="form-err">{errors.countryCode}</div>
        )}
      </div>
      <div className="mb-3">
        <Form.Group as={Row} className=" border-bottom" controlId="number">
          <Form.Label column xs={5} className="p-0 mt-1">
            Mobile Number
          </Form.Label>
          <Col xs={7} className="px-0">
            <InputGroup>
              <InputGroup.Text
                className="border-0 bg-transparent pe-0"
                style={{ width: "50px" }}
              >
                {values.countryCode ? values.countryCode.value : ""}
              </InputGroup.Text>
              <Form.Control
                type="tel"
                className="border-0 shadow-none"
                value={values.phoneNumber}
                onChange={(e) => onChange(e)}
              />
            </InputGroup>
          </Col>
        </Form.Group>
        {errors.phoneNumber && (
          <div className="form-err">{errors.phoneNumber}</div>
        )}
      </div>
    </Form>
  );
};

export default PhoneNumberForm;
