import React, { useState, useEffect, useRef } from "react";
import { Images } from "../../images";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { nextArrow } from "../../icons/icon";
import SignupStep1 from "../../component/Common/SignupComponent/SignupStep1";
import SignupStep2 from "../../component/Common/SignupComponent/SignupStep2";
import LiveProfile from "../../component/Common/SignupComponent/LiveProfile";
import {
  reqToGetVerifyToken,
  signUpUser,
  verifyOtp,
} from "../../store/slice/userSlice";
import Loader from "../../component/Layout/Loader";
import { routes } from "../../helpers/constants";
import { useNavigate } from "react-router";
import VerifyOtp from "../../component/Common/SignupComponent/VerifyOtp";
import moment from "moment";

const Signup = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [signupState, setSignupState] = useState("1");

  const [showLoading, setShowLoading] = useState(true);

  const userReducer = useSelector((state) => state?.user);
  const accessToken = userReducer.profileToken?.token;

  const today = moment().format("YYYY-MM-DD");

  const [formData1, setFormData1] = useState({
    firstName: "",
    lastName: "",
    address: "",
    birthday: "",
    countryCode: {
      label: "Philippines",
      value: "+63",
    },
    phoneNumber: "",
  });

  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState("");

  const [formErrors1, setFormErrors1] = useState({});
  const [formErrors2, setFormErrors2] = useState({});

  const [uniqueApplicantId, setUniqueApplicantId] = useState("");
  const [kycStatus, setKycStatus] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "birthday" && moment(value).isAfter(today)) {
      setFormData1({
        ...formData1,
        birthday: "",
      });
    } else {
      setFormData1({
        ...formData1,
        [name]: value,
      });
    }
    setFormErrors1({
      ...formErrors1,
      [name]: "",
    });
  };

  const handleDateChange = (e) => {
    setFormData1({
      ...formData1,
      birthday: e,
    });
    setFormErrors1({
      ...formErrors1,
      birthday: "",
    });
  };

  const form2ChangeHandler = (e, type) => {
    if (type === "dropdown") {
      setFormData1({
        ...formData1,
        countryCode: e,
      });
      setFormErrors2({
        ...formErrors2,
        countryCode: "",
      });
    } else {
      setFormData1({
        ...formData1,
        phoneNumber: e.target.value,
      });
      setFormErrors2({
        ...formErrors2,
        phoneNumber: "",
      });
    }
  };

  const validateForm1 = () => {
    let errors = {};

    if (!formData1.firstName?.trim()) {
      errors = { ...errors, firstName: "First name is required!" };
    }

    if (!formData1.lastName?.trim()) {
      errors = { ...errors, lastName: "Last Name is required!" };
    }

    if (!formData1.address?.trim()) {
      errors = { ...errors, address: "Address is required!" };
    }

    if (!formData1.birthday) {
      errors = { ...errors, birthday: "Birthday is required!" };
    }

    setFormErrors1(errors);
    return Object.values(errors).every((x) => x === "");
  };

  const phoenNumberValidation = () => {
    let errors = {};
    if (!formData1.countryCode) {
      errors = { ...errors, countryCode: "Select country please!" };
    }
    if (!formData1.phoneNumber?.trim()) {
      errors = { ...errors, phoneNumber: "Please Enter mobile number!" };
    }
    setFormErrors2(errors);
    return Object.values(errors).every((x) => x === "");
  };

  const otpChangeHandler = (e) => {
    setOtp(e);
    setOtpErr("");
  };

  const otpValidation = () => {
    if (!otp) {
      setOtpErr("One time passcode is required!");
    } else if (otp.length < 6) {
      setOtpErr("Please Enter 6 digit OTP!");
    } else {
      return true;
    }
  };

  const nextTabHandler = () => {
    if (signupState === "1") {
      if (validateForm1()) {
        dispatch(reqToGetVerifyToken({})).then(() => setSignupState("2"));
      }
    } else if (signupState === "2") {
      setSignupState("3");
    } else if (signupState === "3") {
      if (phoenNumberValidation()) {
        signUpData();
      }
    }
  };

  const signUpData = () => {
    const data = {
      first_name: formData1.firstName,
      last_name: formData1.lastName,
      phone: formData1.phoneNumber,
      country_code: formData1.countryCode?.value,
      birthdate: formData1.birthday,
      address: formData1.address,
      applicant_id: uniqueApplicantId,
      kyc_status: kycStatus,
    };
    dispatch(
      signUpUser({
        data: data,
        cb: (res) => {
          setSignupState("4");
        },
      })
    );
  };

  const handleSubmitOtpBtn = () => {
    if (otpValidation()) {
      dispatch(
        verifyOtp({
          data: {
            phone: formData1.phoneNumber,
            otp: otp,
          },
        })
      );
    }
  };

  const nextOtpPage = () => {
    const timer = setTimeout(() => {
      setSignupState("3");
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  };

  const delayedResponse = () => {
    if (!ref.current) {
      setKycStatus("pending");
      nextOtpPage();
    }
  };

  const messageHandler = (type, payload) => {
    const { reviewResult, reviewStatus, applicantId, levelName } = payload;

    if (applicantId) {
      setUniqueApplicantId(applicantId);
    }

    if (
      reviewResult?.reviewAnswer === "GREEN" &&
      reviewStatus === "completed"
    ) {
      ref.current = true;
      setKycStatus("approved");
      nextOtpPage();
    } else if (
      reviewResult?.reviewAnswer === "RED" &&
      reviewResult?.reviewRejectType === "FINAL"
    ) {
      ref.current = true;
      setKycStatus("rejected");
      navigate(`${routes.loginPage}`);
    } else if (
      reviewResult?.reviewAnswer === "RED" &&
      reviewResult?.reviewRejectType === "RETRY"
    ) {
      ref.current = true;
    } else if (levelName === "basic-kyc-level" && reviewStatus === "pending") {
      ref.current = null;
      const timeoutId = setTimeout(delayedResponse, 60000);
      return () => clearTimeout(timeoutId);
    }
  };

  return (
    <>
      {userReducer.loader && <Loader />}

      <div className="blank-header">
        <div className="d-flex align-items-center h-100">
          <div className="text-decoration-none d-inline-block mx-auto">
            {/* <img
              src={Images.logoSigla}
              alt="logoSigla"
              className=""
              height={30}
            /> */}
          </div>
          {signupState !== "2" && signupState !== "4" && !showLoading && (
            <Button
              type="button"
              variant="link"
              className="fb_back_btn p-0 text-white"
              onClick={() => nextTabHandler()}
            >
              {nextArrow}
            </Button>
          )}
        </div>
      </div>

      <div className="auth-main-content">
        {showLoading ? (
          <>
            <div className="signup-loader">
              <img
                src={Images.siglaLogo}
                alt="gilasLogoOrange"
                className="img-fluid"
              />
              <h2 className="text-primary ">Senior Citizen App</h2>
            </div>
          </>
        ) : (
          <>
            <div className="auth-form-wrapper">
              {signupState === "1" ? (
                <SignupStep1
                  formData1={formData1}
                  formErrors1={formErrors1}
                  handleInputChange={handleInputChange}
                  nextTabHandler={nextTabHandler}
                  maxDate={today}
                  handleDateChange={handleDateChange}
                />
              ) : signupState === "2" ? (
                <LiveProfile
                  accessToken={accessToken}
                  messageHandler={messageHandler}
                />
              ) : signupState === "3" ? (
                <SignupStep2
                  formData1={formData1}
                  form2ChangeHandler={form2ChangeHandler}
                  formErrors2={formErrors2}
                  nextTabHandler={nextTabHandler}
                />
              ) : (
                <VerifyOtp
                  otpChangeHandler={otpChangeHandler}
                  otp={otp}
                  otpErr={otpErr}
                  handleSubmitOtpBtn={handleSubmitOtpBtn}
                />
              )}
            </div>
          </>
        )}
      </div>
      <div className="blank-footer"></div>
    </>
  );
};

export default Signup;
