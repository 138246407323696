import React, { useState } from "react";
import { Mousewheel, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import LiveBingo from "../../component/Common/GameComponent/LiveBingo";
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import RotatePhone from "../../component/Common/Rotate/RotatePhone";
import { useSelector } from "react-redux";
import Header from "../../component/Layout/Header";
import Loader from "../../component/Layout/Loader";
import BackNav from "../../component/Common/BackNav";
import Footer from "../../component/Layout/Footer";

const Activity = () => {
  const iRef = useRef(null);
  const timeoutRef = useRef(null);

  const userReducer = useSelector((state) => state.user);

  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const [gameState, setGameState] = useState("");
  const [showGame, setShowGame] = useState(false);
  const [gameType, setGameType] = useState("bingo");

  const [preLoaderImg, setPreLoaderImg] = useState("");
  const [postLoaderImg, setPostLoaderImg] = useState("");

  const [wonRewards, setWonRewards] = useState("");

  const [breakTimeOut, setBreakTimeOut] = useState(false);

  const onPlayGameHandler = () => {
    setBreakTimeOut(false);
    setGameState("start");
  };

  const onLoadGame = () => {
    timeoutRef.current = setTimeout(() => {
      if (!breakTimeOut) {
        setShowGame(true);
      }
    }, 2500);
  };

  const goBackBtnShow = () => {
    if (
      (!showGame && !gameState) ||
      (!showGame && gameState) ||
      (showGame && gameState)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const goBack = () => {
    setBreakTimeOut(true);
    setGameState("");
    setShowGame(false);
  };

  const onGameFinishHandler = () => {
    window.onmessage = (e) => {
      if (e?.data?.event_id === "bingo_message") {
        if (e?.data?.bingo_bash_win) {
          showWinORTryAgain("win");
        } else {
          showWinORTryAgain("tryAgain");
        }
      }
    };
  };

  const showWinORTryAgain = (resultType) => {
    const timer = setTimeout(() => {
      if (resultType === "win") {
        setGameState("won");
      } else {
        setGameState("tryAgain");
      }
      setShowGame(false);
    }, 5000);
    return () => clearTimeout(timer);
  };

  const afterWonOrTryAgain = () => {
    setGameState("");
    setShowGame(false);
  };

  return (
    <>
      {!showGame && isLandscape && isMobile ? (
        <RotatePhone type="portrait" />
      ) : (
        <>
          <main className={`main_content ${showGame && "game-main-content"}`}>
            {userReducer.loader && <Loader />}
            {!gameState ? (
              <Header />
            ) : (
              !showGame && (
                <BackNav goBack={goBack} goBackBtnShow={goBackBtnShow} />
              )
            )}
            <div className="home_wrapper">
              <Swiper
                direction={"vertical"}
                slidesPerView={1}
                spaceBetween={30}
                mousewheel={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Mousewheel, Pagination]}
                className="gilas-slider"
              >
                <SwiperSlide>
                  <LiveBingo
                    gameType={gameType}
                    gameState={gameState}
                    iRef={iRef}
                    timeoutRef={timeoutRef}
                    showGame={showGame}
                    preLoaderImg={preLoaderImg}
                    postLoaderImg={postLoaderImg}
                    onPlayGameHandler={onPlayGameHandler}
                    onLoadGame={onLoadGame}
                    breakTimeOut={breakTimeOut}
                    onGameFinishHandler={onGameFinishHandler}
                    wonRewards={wonRewards}
                    afterWonOrTryAgain={afterWonOrTryAgain}
                    userDetail={userReducer.user}
                  />
                </SwiperSlide>
                {/* <SwiperSlide>
            <a href="/activityPreloader">
              <div className="gilas-slide">
                <img
                  src={Images.coverJoinWin}
                  alt="maritesLiveImg"
                  className="img-fluid "
                />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="/activityPreloader">
              <div className="gilas-slide">
                <img
                  src={Images.coverJoinLIVEBingo}
                  alt="totoyLiveImg"
                  className="img-fluid"
                  width={400}
                />
              </div>
            </a>
          </SwiperSlide> */}
              </Swiper>
            </div>
          </main>
          {(!showGame || !gameState) && <Footer />}
        </>
      )}
    </>
  );
};

export default Activity;
