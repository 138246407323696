import React from "react";
import Layout from "./component/Layout/MainLayout";
import { routes } from "./helpers/constants";
import Boost from "./pages/Boost/Boost";
import Broadcast from "./pages/Broadcast/Broadcast";
import Store from "./pages/Store/Store";
import BroadcastVideo from "./pages/Broadcast/BroadcastVideo";
import Profile from "./pages/User/Profile";
import Notification from "./pages/User/Notification";
import Page404 from "./pages/Page404/Page404";
import LoginPage from "./pages/login/LoginPage";
import { Navigate } from "react-router";
import AuthPage from "./component/Layout/AuthPage";
import Prizes from "./pages/Prizes/Prizes";
import Benefits from "./pages/Benefits/Benefits";
import Live from "./pages/Live/Live";
import Verify from "./pages/Benefits/Verify";
import RecieveDetails from "./pages/Benefits/RecieveDetails";
import LiverPreloader from "./pages/Live/LiverPreloader";
import LiveGame from "./pages/Live/LiveGame";
import Activity from "./pages/Activities/Activity";
import ActivityPreloader from "./pages/Activities/ActivityPreloader";
import ActivityDetails from "./pages/Activities/ActivityDetails";
import Tutorial from "./pages/Tutorial/Tutorial";
import Home from "./pages/Home/Home";
import Signup from "./pages/Signup/Signup";

const getRoutes = (user) => [
  {
    path: routes.homepage,
    element: !user ? <AuthPage /> : <Navigate to={routes.tutorialpage} />,
    children: [
      { path: routes.homepage, element: <Navigate to={routes.loginPage} /> },
      { path: routes.loginPage, element: <LoginPage /> },
      { path: routes.signuppage, element: <Signup /> },
    ],
  },
  {
    path: routes.homepage,
    element: user ? <Layout /> : <Navigate to={routes.loginPage} />,
    children: [
      { path: routes.mainPage, element: <Home /> },
      { path: routes.boostpage, element: <Boost /> },
      { path: routes.broadcastpage, element: <Broadcast /> },
      { path: routes.storepage, element: <Store /> },
      { path: routes.broadcastVideopage, element: <BroadcastVideo /> },
      { path: routes.profilepage, element: <Profile /> },
      { path: routes.notificationpage, element: <Notification /> },
      { path: routes.prizesPage, element: <Prizes /> },
      { path: routes.benefitsPage, element: <Benefits /> },
      { path: routes.livePage, element: <Live /> },
      { path: routes.verifyBenifits, element: <Verify /> },
      { path: routes.benifitReceive, element: <RecieveDetails /> },
      { path: routes.livePreloader, element: <LiverPreloader /> },
      { path: routes.liveGame, element: <LiveGame /> },
      { path: routes.activity, element: <Activity /> },
      { path: routes.activityPreloader, element: <ActivityPreloader /> },
      { path: routes.activityDetails, element: <ActivityDetails /> },
      { path: routes.tutorialpage, element: <Tutorial /> },
    ],
  },
  {
    path: "",
    element: !user ? <Navigate to={routes.loginPage} /> : <Page404 />,
    children: [
      { path: routes.loginPage, element: <LoginPage /> },
      { path: "*", element: <LoginPage /> },
    ],
  },
];

export default getRoutes;
