import React from "react";
import { Button, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addBoostId } from "../../store/slice/boostSlice";

const BoostCard = (props) => {
  const { item } = props;
  const dispatch = useDispatch();

  //BOOST JOIN BUTTON HANDLER
  const boostJoinBtnHandler = (item) => {
    const data = {
      boost_id: item.id,
    };
    dispatch(
      addBoostId({
        data,
        toast: "Boost Added successfully",
      })
    );
  };

  return (
    <>
      <Card className="orange_card boost_card px-3 py-4">
        <div className="pe-3">
          <img
            src={item.boost_image}
            alt={item.button}
            style={{ height: "110px", width: "90px" }}
          />
        </div>
        <Card.Body className="p-0">
          <div className="d-flex align-items-start justify-content-between mb-2">
            <Card.Text className="m-0">{item.reason}</Card.Text>
            <Card.Text className="point_text flex-shrink-0 ps-2 m-0">
              {item.points} pts
            </Card.Text>
          </div>
          <Button
            variant="light"
            className="white_btn"
            onClick={() => boostJoinBtnHandler(item)}
          >
            {item.button}
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default BoostCard;
