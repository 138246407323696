import React from "react";
import { Images } from "../../../images";
import { reloadIcon } from "../../../icons/icon";

const TryAgain = ({
  gameType,
  reStartGame,
  postLoaderImg,
  gameName,
  winMsg,
  lastNumOfWinsByUser,
  numOfGamesForRewards,
}) => {
  return (
    <div className="flex_wrapper">
      <div className="lose_wrapper text-center">
        <h4 className="fw-bold mb-2">{gameName}</h4>
        <div className="lose_text">TRY AGAIN!</div>
        <div className="lose_reward fw-bold text-blue">
          {winMsg ? winMsg : "and win this"}
        </div>
        <div className="my-auto lose_img">
          <img
            src={postLoaderImg ? postLoaderImg : Images.loseGift}
            alt="reward"
            className="img-fluid"
          />
        </div>
        {gameType === "play" || gameType === "learn" ? (
          <div
            className="d-flex justify-content-between align-items-center w-100 "
            style={{ padding: "0px 14px" }}
          >
            <h2>WIN:</h2>
            <h2>{lastNumOfWinsByUser + " / " + numOfGamesForRewards}</h2>
            <button className="reload-button" onClick={() => reStartGame()}>
              {reloadIcon}
            </button>
          </div>
        ) : (
          <div className="mt-auto d-flex justify-content-center align-items-center w-100">
            <button className="reload-button" onClick={() => reStartGame()}>
              {reloadIcon}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TryAgain;
