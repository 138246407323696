import React from "react";
import { Outlet } from "react-router";
import BackArrowNav from "../Common/BackArrowNav";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Footer2 from "./Footer2";
import BackNav from "../Common/BackNav";

const Layout = () => {
  const location = useLocation();
  const { pathname } = location;

  const showNavFoot = () => {
    if (pathname === "/live") {
      return true;
    } else if (pathname === "/boost") {
      return true;
    } else if (pathname === "/broadcast") {
      return true;
    } else if (pathname === "/home") {
      return true;
    } else {
      return false;
    }
  };

  const showFoot2 = () => {
    if (pathname == "/benifitPreloader") {
      return true;
    } else if (pathname === "/tutorial") {
      return true;
    } else {
      return false;
    }
  };

  const goBackBtnShow = () => {
    return false;
  };

  return (
    <div className="layout">
      {showNavFoot() ? (
        <Header />
      ) : pathname === "/tutorial" ? (
        <BackNav goBackBtnShow={goBackBtnShow} />
      ) : (
        pathname !== "/prizes" &&
        pathname !== "/benefits" &&
        pathname !== "/activity" && <BackArrowNav />
      )}
      <Outlet />
      {showNavFoot() && <Footer />}
      {showFoot2() && <Footer2 />}
    </div>
  );
};

export default Layout;
