import React from "react";
import { backArrowIcon } from "../../icons/icon";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Images } from "../../images";

const BackNav = ({ goBack, goBackBtnShow, benefitState }) => {
  return (
    <>
      <div className="fb_navbar fixed-top">
        {goBackBtnShow() && (
          <Button
            type="button"
            variant="link"
            className="fb_back_btn p-0 text-white"
            onClick={() => goBack()}
          >
            {backArrowIcon}
          </Button>
        )}
        <Link to="/" className="text-decoration-none d-inline-block mx-auto">
          {benefitState === "uploadeImage" ? (
            <span className="nav-center-title">Monthly Allowance</span>
          ) : benefitState === "uploadeSelfie" ? (
            <span className="nav-center-title">Take Selfie</span>
          ) : (
            <img
              src={Images.newHeaderLogo}
              alt="logoSigla"
              className=""
              height={30}
            />
          )}
        </Link>
      </div>
    </>
  );
};

export default BackNav;
