import React, { useState, useEffect } from "react";
import { Mousewheel, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Images } from "../../images";
import video from "../../images/liveCoverBingo.mp4";

const Live = () => {
  const [showPoster, setShowPoster] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPoster(false);
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <main className={`main_content`}>
      <div className="home_wrapper">
        <Swiper
          direction={"vertical"}
          slidesPerView={1}
          spaceBetween={30}
          mousewheel={true}
          pagination={{
            clickable: true,
          }}
          modules={[Mousewheel, Pagination]}
          className="gilas-slider live-slider"
        >
          <SwiperSlide>
            <a href="/livePreloader">
              <div className="gilas-slide live-slide">
                {/* <img
                  src={Images.coverBingo}
                  alt="bingoLiveImg"
                  className="img-fluid "
                /> */}
                <div className="screen_bg">
                  <video autoPlay muted loop poster={Images.coverBingo}>
                    {showPoster ? (
                      <>
                        <img
                          src={Images.coverBingo}
                          alt="Poster"
                          className="poster_img"
                        />
                        <p>Loading...</p>
                      </>
                    ) : (
                      <>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </>
                    )}
                  </video>
                </div>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="/livePreloader">
              <div className="gilas-slide live-slide">
                <img
                  src={Images.coverAerobics}
                  alt="maritesLiveImg"
                  className="img-fluid "
                />
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="/livePreloader">
              <div className="gilas-slide live-slide">
                <img
                  src={Images.coverTaichi}
                  alt="totoyLiveImg"
                  className="img-fluid"
                  width={400}
                />
              </div>
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
    </main>
  );
};

export default Live;
