import React from "react";
import { Button } from "react-bootstrap";
import OTPInput from "react-otp-input";
import { rightArrow } from "../../../icons/icon";

const VerifyOtp = ({ otp, otpChangeHandler, handleSubmitOtpBtn, otpErr }) => {
  return (
    <div className="signup-step-3">
      <h2 className="text-center text-secondary mb-4">Enter OTP Code:</h2>
      <OTPInput
        value={otp}
        onChange={(e) => otpChangeHandler(e)}
        numInputs={6}
        renderSeparator={<span>&nbsp;&nbsp;</span>}
        renderInput={(props) => <input {...props} />}
        containerStyle="otp-field"
        inputStyle="form-control"
        inputType="tel"
      />
      {otpErr && <div className="form-err mt-1">{otpErr}</div>}
      {/* <Button
        variant="secondary"
        type="submit"
        className="submit-btn w-100"
        onClick={handleSubmitOtpBtn}
      >
        Submit
      </Button> */}
      <Button
        variant="secondary"
        className="tc-save login-button mt-5 w-50"
        type="submit"
        onClick={handleSubmitOtpBtn}
      >
        Submit
        {rightArrow}
      </Button>
    </div>
  );
};

export default VerifyOtp;
