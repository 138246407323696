import React from "react";

const LiveBingoGame = ({ iRef, onGameFinishHandler, userDetail }) => {
  const timeStamp = new Date().getTime();

  return (
    <div className="game-content">
      <div className="game-initailize">
        <iframe
          width="100%"
          id="live-bingo-iframe"
          height=""
          src={`${process.env.REACT_APP_ONLINE_BINGO_GAME_BASE_URL}?user=${userDetail.first_name}`}
          ref={(component) => {
            iRef.current = component;
          }}
          onLoad={() => onGameFinishHandler()}
        />
      </div>
    </div>
  );
};

export default LiveBingoGame;
