import React, { useState, useEffect, useRef } from "react";
import { Mousewheel, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {
  collectBenefit,
  getBenefitList,
  getGuarantedRewards,
  getRandomTicketReward,
  getUploadedImageData,
  reqToClaimBenefit,
  reqToCollectBenifit,
} from "../../store/slice/benefitSlice";
import { useDispatch, useSelector } from "react-redux";
import { reqToGetUserDetailById } from "../../store/slice/userSlice";
import { useMediaQuery } from "react-responsive";
import RotatePhone from "../../component/Common/Rotate/RotatePhone";
import Loader from "../../component/Layout/Loader";
import Header from "../../component/Layout/Header";
import Footer from "../../component/Layout/Footer";
import BackNav from "../../component/Common/BackNav";
import BenefitItem from "../../component/Common/BenefitComponent/BenefitItem";
import { generateAndCheckRandom } from "../../helpers/commonFunction";

const Benefits = () => {
  const timeoutRef = useRef(null);
  const dispatch = useDispatch();

  const userReducer = useSelector((state) => state.user);
  const benefitReducer = useSelector((state) => state.benefit);

  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const [benefitList, setBenefitList] = useState([]);

  const [benefitTab, setBenefitTab] = useState("Cash");
  const [benefitState, setBenefitState] = useState("");

  const [benefitClaimType, setBenefitClaimType] = useState("");
  const [benefitDueDay, setBenefitDueDay] = useState(0);

  const [breakTimeOut, setBreakTimeOut] = useState(false);

  useEffect(() => {
    if (benefitReducer.benefitList.length !== 0) {
      setBenefitList(benefitReducer.benefitList);
      // setBenefitTab(benefitReducer.benefitList[0].type);
    }
  }, [benefitReducer.benefitList]);

  useEffect(() => {
    dispatch(reqToGetUserDetailById({}));
    dispatch(getBenefitList());
  }, []);

  const preLoaderStateHandler = (data) => {
    setBenefitTab(data.type);
    setBreakTimeOut(false);
    setBenefitState("loader");
    setBenefitClaimType("collect");
    // const data = {
    //   item_id: id,
    // };
    // dispatch(
    //   reqToClaimBenefit({
    //     data: data,
    //     cb: (res) => {
    //       if (res?.status) {
    //         setBenefitClaimType(res?.data?.claim);
    //         if (res?.data?.days) {
    //           setBenefitDueDay(res?.data?.days);
    //         }
    //         // setBenefitState("loader");
    //       }
    //     },
    //   })
    // );
  };

  const collectClaimHandler = (id) => {
    setBenefitState("uploadeImage");
    // setBenefitState("congrats");
    // const data = {
    //   item_id: id,
    // };
    // setBenefitState("congrats");
    // dispatch(
    //   collectBenefit({
    //     data: data,
    //     cb: (res) => {
    //       if (res?.status) {
    //         setBenefitState("congrats");
    //       }
    //     },
    //   })
    // );
  };

  const nextToCongratsHandler = (type, ticket_ern, points_ern) => {
    if (type === "random") {
      if (generateAndCheckRandom()) {
        dispatch(
          getRandomTicketReward({
            data: { tickets: 1 },
            cb: (res) => {
              if (res?.status) {
                setBenefitState("randomReward");
              }
            },
          })
        );
      } else {
        finishClaimHandler();
      }
    } else {
      dispatch(
        getGuarantedRewards({
          data: {
            tickets: ticket_ern,
            points: points_ern,
          },
          cb: (res) => {
            if (res?.status) {
              setBenefitState("rewardEarned");
            }
          },
        })
      );
    }
  };

  const finishClaimHandler = () => {
    setBenefitState("");
  };

  const goBack = () => {
    if (benefitState === "uploadeSelfie") {
      setBenefitState("uploadeImage");
    } else if (benefitState === "uploadeImage") {
      setBenefitState("collect");
    } else {
      setBreakTimeOut(true);
      setBenefitState("");
    }
  };

  const goBackBtnShow = () => {
    if (
      benefitState === "loader" ||
      benefitState === "collect" ||
      benefitState === "uploadeImage" ||
      benefitState === "uploadeSelfie"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onLoadFinish = () => {
    timeoutRef.current = setTimeout(() => {
      if (!breakTimeOut) {
        setBenefitState(benefitClaimType);
      }
    }, 2500);
  };

  // on  swipp hanlder
  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    setBenefitTab(benefitList[activeIndex].type);
  };

  // Handle User ID image and Selfie
  const handleCollectBenifit = () => {
    dispatch(
      reqToCollectBenifit({
        data: {
          sc_id_image: benefitReducer.uploadImageData.image,
          gcash_number: benefitReducer.uploadImageData.gCaseNumber,
        },
        onSuccess: (data) => {
          dispatch(getUploadedImageData({ image: "", gCaseNumber: "" }));
          setBenefitState("congrats");
        },
      })
    );
  };

  return (
    <>
      {isLandscape && isMobile ? (
        <RotatePhone type="portrait" />
      ) : (
        <>
          <main className={`main_content`}>
            {(benefitReducer.loader || userReducer.loader) && <Loader />}
            {!benefitState ? (
              <Header />
            ) : (
              <BackNav
                goBack={goBack}
                goBackBtnShow={goBackBtnShow}
                benefitState={benefitState}
              />
            )}
            <div className="home_wrapper">
              {benefitList.map((item, i) => {
                if (item.type === "Cash") {
                  return (
                    <BenefitItem
                      key={i}
                      benefitTab={benefitTab}
                      benefitState={benefitState}
                      setBenefitState={setBenefitState}
                      benefitD={item}
                      preLoaderStateHandler={preLoaderStateHandler}
                      onLoadFinish={onLoadFinish}
                      timeoutRef={timeoutRef}
                      breakTimeOut={breakTimeOut}
                      benefitClaimType={benefitClaimType}
                      collectClaimHandler={collectClaimHandler}
                      nextToCongratsHandler={nextToCongratsHandler}
                      finishClaimHandler={finishClaimHandler}
                      benefitDueDay={benefitDueDay}
                      handleCollectBenifit={handleCollectBenifit}
                    />
                  );
                }
              })}
              {/* <Swiper
                direction={"vertical"}
                slidesPerView={1}
                // spaceBetween={30}
                mousewheel={true}
                pagination={
                  benefitState
                    ? false
                    : {
                        clickable: true,
                      }
                }
                allowSlidePrev={benefitState ? false : true}
                allowSlideNext={benefitState ? false : true}
                allowTouchMove={benefitState ? false : true}
                modules={[Mousewheel, Pagination]}
                className="gilas-slider"
                style={{ background: "#ffffff" }}
                onSlideChange={handleSlideChange}
              >
                {benefitList?.map((item, i) => (
                  <SwiperSlide key={i}>
                    <BenefitItem
                      benefitTab={benefitTab}
                      benefitState={benefitState}
                      setBenefitState={setBenefitState}
                      benefitD={item}
                      preLoaderStateHandler={preLoaderStateHandler}
                      onLoadFinish={onLoadFinish}
                      timeoutRef={timeoutRef}
                      breakTimeOut={breakTimeOut}
                      benefitClaimType={benefitClaimType}
                      collectClaimHandler={collectClaimHandler}
                      nextToCongratsHandler={nextToCongratsHandler}
                      finishClaimHandler={finishClaimHandler}
                      benefitDueDay={benefitDueDay}
                      handleCollectBenifit={handleCollectBenifit}
                    />
                  </SwiperSlide>
                ))}
              </Swiper> */}
            </div>
          </main>
          <Footer />
        </>
      )}
    </>
  );
};

export default Benefits;
