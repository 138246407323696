import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

import LiveBingoGame from "../../Games/LiveBingoGame";
import { Images } from "../../../images";
import bingoVideo from "../../../images/liveBingoRecording.mp4";
import Preloader from "../../GilasLoaders/PreLoader";
import LiveGameWon from "../Win/LiveGameWon";
import LiveGameTryAgain from "../TryAgain/LiveGameTryAgain";

const LiveBingo = ({
  iRef,
  timeoutRef,
  gameType,
  gameState,
  showGame,
  preLoaderImg,
  postLoaderImg,
  onPlayGameHandler,
  onLoadGame,
  breakTimeOut,
  onGameFinishHandler,
  wonRewards,
  afterWonOrTryAgain,
  userDetail,
}) => {
  const [showPoster, setShowPoster] = useState(true);

  useEffect(() => {
    if (gameType === "bingo") {
      const timer = setTimeout(() => {
        setShowPoster(false);
      }, 1500);
      return () => {
        clearTimeout(timer);
      };
    } else {
      setShowPoster(true);
    }
  }, [gameType]);

  return (
    <>
      {!gameState ? (
        <>
          <div className="home_wrapper" onClick={() => onPlayGameHandler()}>
            <div className="screen_bg">
              <video
                autoPlay
                muted
                loop
                playsInline
                poster={Images.coverImgJoinActivities3}
              >
                {showPoster ? (
                  <>
                    <img
                      src="your-poster-image.jpg"
                      alt="Poster"
                      className="poster_img"
                    />
                    <p>Loading...</p>
                  </>
                ) : (
                  <>
                    <source src={bingoVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </>
                )}
              </video>
            </div>
            <div className="home_content">
              <div className="">
                <div className="play_home">
                  <Button
                    variant="primary"
                    size="lg"
                    onClick={() => onPlayGameHandler()}
                  >
                    JOIN
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : gameState === "start" && gameType === "bingo" ? (
        <>
          {!showGame && (
            <Preloader
              preLoaderImg={preLoaderImg}
              onLoadGame={onLoadGame}
              timeoutRef={timeoutRef}
              breakTimeOut={breakTimeOut}
            />
          )}
          <div>
            <LiveBingoGame
              iRef={iRef}
              onGameFinishHandler={onGameFinishHandler}
              userDetail={userDetail}
            />
          </div>
        </>
      ) : gameState === "tryAgain" && gameType === "bingo" ? (
        <LiveGameTryAgain
          // reStartGame={reStartGame}
          postLoaderImg={postLoaderImg}
          gameName="LIVE BINGO"
          afterWonOrTryAgain={afterWonOrTryAgain}
        />
      ) : (
        gameType === "bingo" && (
          <LiveGameWon
            // reStartGame={reStartGame}
            wonRewards={wonRewards}
            gameName="LIVE BINGO"
            afterWonOrTryAgain={afterWonOrTryAgain}
          />
        )
      )}
    </>
  );
};

export default LiveBingo;
