import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { backArrowIcon, userDetailIcon } from "../../icons/icon";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { reqToGetUserDetailById } from "../../store/slice/userSlice";
import { Images } from "../../images";

const BackArrowNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    dispatch(reqToGetUserDetailById({}));
  }, []);

  const userD = useSelector((state) => state.user.userDetail);

  const getHeaderName = () => {
    if (pathname === "/store") {
      let points = userD.total_point
        ? userD.total_point + " pts"
        : "0" + " pts";
      return points;
    } else if (pathname === "/boost") {
      return "BOOST";
    } else if (pathname === "/broadcast" || pathname === "/broadcastVideo") {
      return "Broadcast";
    } else if (pathname === "/profile" || pathname === "/notification") {
      let name = userD.first_name
        ? userD.first_name + " " + `${userD.last_name ? userD.last_name : ""}`
        : "";
      return name;
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="fb_navbar fixed-top">
        <Button
          type="button"
          variant="link"
          className="fb_back_btn p-0 text-white"
          onClick={() => navigate(-1)}
        >
          {backArrowIcon}
        </Button>

        {getHeaderName() ? (
          <div
            className={`${
              pathname === "/store" ? "point_text" : "fs-2"
            } center_align m-auto`}
          >
            {getHeaderName()}
          </div>
        ) : (
          <Link
            to="/home"
            className="text-decoration-none d-inline-block mx-auto"
          >
            <img
              src={Images.newHeaderLogo}
              alt="logoSigla"
              className=""
              height={30}
            />
          </Link>
        )}
        {pathname === "/notification" && <div>{userDetailIcon}</div>}
      </div>
    </>
  );
};

export default BackArrowNav;
