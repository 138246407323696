import React from "react";
import PhoneNumberForm from "./PhoneNumberForm";
import { rightArrow } from "../../../icons/icon";

const SignupStep2 = ({
  formData1,
  form2ChangeHandler,
  formErrors2,
  nextTabHandler,
}) => {
  return (
    <div className="signup-step-2">
      {/* <h2 className="text-center text-secondary mb-4">Sign Up</h2> */}
      <h3 className="text-center text-secondary mb-5">
        Enter your mobile number for verification
      </h3>
      <PhoneNumberForm
        values={formData1}
        errors={formErrors2}
        onChange={form2ChangeHandler}
      />
      <button
        className="tc-save login-button mt-5"
        onClick={() => nextTabHandler()}
      >
        Next
        {rightArrow}
      </button>
    </div>
  );
};

export default SignupStep2;
