const ncscLogo = require("./flappy_NCSC_logo.png");
const ticket = require("./ticket.png");
const loseGift = require("./flappy_lose.png");
const winPoint = require("./points.png");
const bingoBg4 = require("./bingo_bg_4.png");
const profileCard = require("./fb_profile_card.png");
const santaGameBg = require("./santa-game.png");
const quizGameBg2 = require("./quiz_game_bg2.png");
const winPeso = require("./winPeso.png");
const thousandPeso = require("./1000_peso_bill.png");
const givingGroceries = require("./givingFreeGroceries.png");
const givingMedicines = require("./givingMedicines.png");
const monthlyAllowance = require("./monthlyAllowance.png");
const confeti = require("./confeti.png");
const confeti2 = require("./confeti2.png");
const transferMoney = require("./transferMoney.png");
const coverMonthlyAllowance = require("./coverImage_MonthlyAllowance.png");
const coverFreegroceries = require("./coverImage_freegroceries.png");
const coverFreemedicines = require("./coverImage_freemedicines.png");
const benifitIcon = require("./benefits.png");
const prizesIcon = require("./prizes.png");
const liveIcon = require("./live.png");
const videosIcon = require("./videos.png");
const bingoLiveImg = require("./6329747.png");
const maritesLiveImg = require("./marites.png");
const totoyLiveImg = require("./totoy.png");
const advance = require("./advance.png");
const invite = require("./invite.png");
const register = require("./register.png");
const coverAerobics = require("./coverImage_aerobics.png");
const coverBingo = require("./coverImage_bingo.png");
const coverTaichi = require("./coverImage_taichi.png");
const coverFAQ = require("./coverImage_FAQ.png");
const coverJoinWin = require("./coverImage_JoinWin.png");
const coverJoinLIVEBingo = require("./coverImage_LIVEBingo.png");
const cover1k = require("./coverImage_1k.png");
const coverSIGLA = require("./coverImage_SIGLA.png");
const coverBingoImg = require("./coverBingoImg.png");
const activityIcon = require("./activities.png");
const logoSigla = require("./logo.png");
// const getBenefits = require("./getBenefits.png");
const getBenefits = require("./getBenefits1.png");
const winPrizes = require("./winPrizes.png");
const joinActivities = require("./joinActivities.png");
const gilasLogoOrange = require("./gilas-logo.png");
const gilasLogo = require("./gilas-white-logo.png");
const groceryReward = require("./grocery.png");
const scratch = require("./scratch.png");

const oppsImg = require("./oppsImg.png");

const lastBattleBg = require("./lastBattleBg.png");
const whatYouCanDo = require("./whatYouCanDo.png");
const getBenefits1 = require("./1_getBenefits.png");
const winPrizes2 = require("./2_winPrizes.png");
const joinActivities3 = require("./3_joinActivities.png");
const coverJoinActivities = require("./coverImage_JoinActivities.png");
const coverImgJoinActivities3 = require("./coverImage_join_activities_2.png");
const liveBingoPreLoader = require("./LiveBingoPreloader.png");

const siglaLogo = require("./sigla-logo.png");
const siglaLogo2 = require("./sigla-logo-screen-2.png");

const pestCoverImg = require("./coverImage_pest.png");
const christmasGiftBg = require("./christmasGiftBg.png");
const cameraImg = require("./taptoUpload.png");

// const selfieImg = require("./selfie.png");
const selfieImg = require("./verify.png");

const pesoImgAfterCount5 = require("./peso-img-count-5.png");

const congretsImg = require("./postloader.png");
const newHeaderLogo = require("./new_header_logo.png");

export {
  siglaLogo2,
  liveBingoPreLoader,
  coverImgJoinActivities3,
  newHeaderLogo,
  ncscLogo,
  ticket,
  loseGift,
  winPoint,
  bingoBg4,
  profileCard,
  santaGameBg,
  quizGameBg2,
  winPeso,
  thousandPeso,
  givingGroceries,
  givingMedicines,
  monthlyAllowance,
  confeti,
  confeti2,
  transferMoney,
  coverMonthlyAllowance,
  coverFreegroceries,
  coverFreemedicines,
  benifitIcon,
  prizesIcon,
  liveIcon,
  videosIcon,
  bingoLiveImg,
  maritesLiveImg,
  totoyLiveImg,
  advance,
  invite,
  register,
  coverAerobics,
  coverBingo,
  coverTaichi,
  coverFAQ,
  coverJoinWin,
  coverJoinLIVEBingo,
  cover1k,
  coverSIGLA,
  coverBingoImg,
  activityIcon,
  logoSigla,
  getBenefits,
  winPrizes,
  joinActivities,
  gilasLogoOrange,
  gilasLogo,
  lastBattleBg,
  oppsImg,
  groceryReward,
  scratch,
  whatYouCanDo,
  getBenefits1,
  winPrizes2,
  joinActivities3,
  coverJoinActivities,
  siglaLogo,
  pestCoverImg,
  christmasGiftBg,
  cameraImg,
  selfieImg,
  pesoImgAfterCount5,
  congretsImg,
};
