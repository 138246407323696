import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/userSlice";
import broadcastReducer from "./slice/broadCastSlice";
import gameReducer from "./slice/gameSlice";
import storeReducer from "./slice/storeSlice";
import boostReducer from "./slice/boostSlice";
import benefitReducer from "./slice/benefitSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    broadcast: broadcastReducer,
    game: gameReducer,
    store: storeReducer,
    boost: boostReducer,
    benefit: benefitReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
