import React from "react";
import NotificationCard from "../../component/Common/NotificationCard";
import { calendarCashIcon, medicineIcon } from "../../icons/icon";
import Footer from "../../component/Layout/Footer";
import { Images } from "../../images";
import { Link } from "react-router-dom";

const Notification = () => {
  const notificationData = [
    {
      Icon: medicineIcon,
      title: "Here are your Free Medicines. Tap ‘YES’ button to receive.",
      btnText: "YES",
    },
    {
      Icon: calendarCashIcon,
      title: "Here is your Monthly Allowance. Tap ‘COLLECT’ button to receive.",
      btnText: "COLLECT",
    },
  ];
  return (
    <>
      <main className="main_content">
        <div className="notification_wrapper p-3">
          {notificationData.map((item, index) => {
            return (
              <NotificationCard
                key={index}
                Icon={item.Icon}
                title={item.title}
                btnText={item.btnText}
              />
            );
          })}
          <Link to="/benifitReceive" className="text-decoration-none">
            <NotificationCard
              Icon={
                <img
                  src={Images.transferMoney}
                  alt="icon"
                  className="img-fluid svg-icon"
                />
              }
              title="Your SC P1,000.00 Monthly Allowance successfully sent."
              noticeTime="2 mins ago"
            />
          </Link>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Notification;
