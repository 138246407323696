import React from "react";
import { Button, Card } from "react-bootstrap";

const BenifitCard = ({ cardObj, collectClaimHandler }) => {
  return (
    <>
      <Card className="orange_card benifit_card px-3 py-4">
        <div className="pe-3">
          <img src={cardObj.icon} alt="icon" />
        </div>
        <Card.Body className="p-0 text-center">
          <div className="mb-3">
            <Card.Text className="m-0">{cardObj.title}</Card.Text>
          </div>
          {cardObj.showCollect && (
            <div className="d-block text-end">
              <Button
                variant="light"
                className="white_btn"
                onClick={() => collectClaimHandler(cardObj.benefitId)}
              >
                {/* COLLECT */}
                VERIFY
              </Button>
            </div>
          )}
          {cardObj.showDueDay && (
            <div>
              <span className="big-text">{cardObj.dueDays}</span>
              <span>days</span>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default BenifitCard;
