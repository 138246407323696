import React from "react";
import { userIcon } from "../../icons/icon";
import { Col, Row } from "react-bootstrap";
import { Images } from "../../images";
import Footer from "../../component/Layout/Footer";

const Profile = () => {
  const details =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <main className="main_content">
        <div className="profile_wrapper p-3">
          <div className="d-flex align-items-center mb-4">
            <div className="profile_img flex-shrink-0 z-1">{userIcon}</div>
            <div className="profile_header flex-grow-1 ">
              <p className="text-primary fw-medium m-0">SC ID No.:</p>
              <h6 className="m-0 fw-bold fs-4">12345678</h6>
            </div>
          </div>
          <div className="profile_details fw-medium">
            {details && details.first_name && (
              // && details.last_name
              <Row className="mb-2">
                <Col xs={4}>Name:</Col>
                <Col xs={8}>
                  {details.first_name}
                  {details.last_name ? details.last_name : ""}
                </Col>
              </Row>
            )}

            {details && details.address && (
              <Row className="mb-2">
                <Col xs={4}>Address:</Col>
                <Col xs={8}>{details.address}</Col>
              </Row>
            )}
            {details && details.birthdate && (
              <Row className="mb-2">
                <Col xs={4}>Birthdate:</Col>
                <Col xs={8}>{details.birthdate}</Col>
              </Row>
            )}
            {details && details.age && (
              <Row className="mb-2">
                <Col xs={4}>Age:</Col>
                <Col xs={8}>{details.age}</Col>
              </Row>
            )}
            {details && details.gender && (
              <Row className="mb-2">
                <Col xs={4}>Gender:</Col>
                <Col xs={8}>{details.gender}</Col>
              </Row>
            )}
            {details && details.phone && (
              <Row className="mb-2">
                <Col xs={4}>Contact No.:</Col>
                <Col xs={8}>{details.phone}</Col>
              </Row>
            )}
          </div>
          <div className="mt-4 text-center profile_card_img">
            <img
              src={Images.profileCard}
              alt="profile-card"
              className="img-fluid"
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Profile;
