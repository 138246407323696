import React from "react";
import { Button, Card } from "react-bootstrap";

const ActivtyCard = (props) => {
  return (
    <>
      <Card className="orange_flat_card boost_card">
        <Card.Body className="p-0">
          <div className="w-100 text-end">
            <span className="point_text m-0">{props.points} pts</span>
          </div>
          <div className="my-2">
            <img src={props.Icon} alt="icon" />
            <h2 className="mt-2 mb-5">{props.title}</h2>
          </div>
          <Button variant="primary">{props.button}</Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default ActivtyCard;
