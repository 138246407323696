import React from "react";

const PurchaseModal = ({ closeModal, purchaseStoreHandler }) => {
  return (
    <>
      <div className="tc-play-modal-text">
        Are you sure, you want to purchase it?
      </div>

      <div className="tc-play-modal-btn">
        <button
          type="button"
          className="modal-cancel-btn"
          onClick={() => closeModal()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="modal-second-btn"
          onClick={() => purchaseStoreHandler()}
        >
          Yes
        </button>
      </div>
    </>
  );
};

export default PurchaseModal;
