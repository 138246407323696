import React, { useEffect, useState } from "react";
import { Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import StoreItem from "../../component/Common/StoreItem";
import { useDispatch, useSelector } from "react-redux";
import { byStore, getStoreData } from "../../store/slice/storeSlice";
import Loader from "../../component/Layout/Loader";
import NoData from "../../component/Common/NoDataFound/NoData";
import { reqToGetUserDetailById } from "../../store/slice/userSlice";
import PurchaseModal from "../../component/modal/PurchaseModal";

const Store = () => {
  const dispatch = useDispatch();

  const storeReducer = useSelector((state) => state.store);
  const { storeData } = storeReducer;

  const [tab, setTab] = useState("Grocery");
  const [showModal, setShowModal] = useState(false);
  const [productId, setProductId] = useState("");

  const closeModal = () => {
    setShowModal(false);
    setProductId("");
  };

  useEffect(() => {
    dispatch(getStoreData({ type: tab }));
  }, [tab]);

  const storeTabs = [
    { eventKey: "Grocery" },
    { eventKey: "Medicine" },
    { eventKey: "Cash" },
  ];

  const onChangeTab = (eventKey) => {
    setTab(eventKey);
  };

  const navBtns = [
    { eventKey: "Grocery", label: "Groceries" },
    { eventKey: "Medicine", label: "Medicines" },
    { eventKey: "Cash", label: "Cashout" },
  ];

  const showModalHandler = (store) => {
    setProductId(store.id);
    setShowModal(true);
  };

  const purchaseStoreHandler = () => {
    const data = {
      store_id: productId,
    };
    dispatch(
      byStore({
        data: data,
        cb: (res) => {
          if (res.status) {
            dispatch(reqToGetUserDetailById({}));
          }
          setShowModal(false);
          setProductId("");
        },
      })
    );
  };

  return (
    <>
      <main className="main_content">
        {storeReducer.loader && <Loader />}
        <div className="store_wrapper">
          <Tab.Container id="left-tabs-example" defaultActiveKey="Grocery">
            <Tab.Content>
              {storeTabs.map((tab, i) => (
                <Tab.Pane eventKey={tab.eventKey} key={i}>
                  <Row className="m-0">
                    {storeData?.length === 0 ? (
                      <NoData />
                    ) : (
                      storeData?.map((store, index) => {
                        return (
                          <Col
                            xs={6}
                            className="store_col py-4"
                            key={index}
                            onClick={() => showModalHandler(store)}
                          >
                            <StoreItem store={store} />
                          </Col>
                        );
                      })
                    )}
                  </Row>
                </Tab.Pane>
              ))}
            </Tab.Content>

            <Nav
              className="store_tabs fixed-bottom"
              onSelect={(selectedKey) => onChangeTab(selectedKey)}
            >
              {navBtns.map((btn, i) => (
                <Nav.Item key={i}>
                  <Nav.Link eventKey={btn.eventKey}>{btn.label}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Tab.Container>
        </div>
      </main>
      <Modal
        className="tc-play-modal"
        show={showModal}
        onHide={closeModal}
        centered
      >
        <Modal.Body>
          <PurchaseModal
            purchaseStoreHandler={purchaseStoreHandler}
            closeModal={closeModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Store;
