import React from "react";

const StoreItem = ({ store }) => {
  return (
    <>
      <div className="store_item_wrapper">
        <h5>{store.points ? store.points : ""} pts</h5>

        <div className="ms-auto">
          <img
            src={store.image_url ? store.image_url : ""}
            alt="store-item"
            className="img-fluid ms-2"
          />
        </div>

        <h5 className="fs-6">
          P{store.peso ? store.peso : ""} {store.type ? store.type : ""} Package
        </h5>
      </div>
    </>
  );
};

export default StoreItem;
