import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/css/style.css";
import getRoutes from "./routes";
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";

const App = () => {
  const user = useSelector((state) => state.user.user);

  const routing = useRoutes(getRoutes(user));
  return <>{routing}</>;
};

export default App;
