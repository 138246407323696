import React from "react";
import { Images } from "../../../images";
import { reloadIcon } from "../../../icons/icon";
import ScratchCard from "react-scratchcard-v2";

const Won = ({
  reStartGame,
  wonRewards,
  gameName,
  gameType,
  lastNumOfWinsByUser,
  numOfGamesForRewards,
}) => {
  const data =
    gameType === "play"
      ? wonRewards === "noRewards"
        ? {
            // img: Images.ticket,
          }
        : wonRewards?.prize_type?.includes("ticket")
        ? {
            img: Images.ticket,
            reward: wonRewards?.tickets + " Tickets",
          }
        : {
            img:
              wonRewards?.prize_type === "Consolation" ||
              wonRewards?.prize_type === "Minor"
                ? Images.winPoint
                : Images.groceryReward,
            // reward: "Scratch to Win",

            rewardDesc:
              wonRewards?.prize_type === "Consolation" ||
              wonRewards?.prize_type === "Minor"
                ? wonRewards.points + " Points"
                : "Noche Buena package",
            scratch: true,
          }
      : gameType === "learn"
      ? wonRewards === "noRewards"
        ? {
            // img: Images.ticket,
          }
        : wonRewards?.prize_type?.includes("ticket")
        ? {
            img: Images.ticket,
            reward: wonRewards?.tickets + " Tickets",
          }
        : {
            img:
              wonRewards?.prize_type === "Consolation" ||
              wonRewards?.prize_type === "Minor"
                ? Images.winPoint
                : Images.groceryReward,
            // reward: "Scratch to Win",

            rewardDesc:
              wonRewards?.prize_type === "Consolation" ||
              wonRewards?.prize_type === "Minor"
                ? wonRewards.points + " Points"
                : "Noche Buena package",
            scratch: true,
          }
      : wonRewards && wonRewards?.prize_type === "Consolation"
      ? {
          img: Images.ticket,
          reward: wonRewards?.tickets + " Tickets",
        }
      : wonRewards && wonRewards?.prize_type === "Minor"
      ? {
          img: Images.winPoint,
          reward: wonRewards?.points + " Points",
        }
      : {
          img: Images.winPeso,
          reward: "P" + wonRewards?.peso,
        };

  return (
    <>
      <div className="flex_wrapper">
        {/* <div className="win_wrapper text-center"> */}
        <div className="last_game_win_reward_wrapper text-center">
          <h4 className="fw-bold">{gameName}</h4>
          {lastNumOfWinsByUser == 4 ? (
            <img
              src={wonRewards?.prize_last_image}
              alt="reward"
              className={`img-fluid  last_prize_img`}
            />
          ) : (
            <div>
              <div className="win_text">
                {wonRewards === "noRewards" ? "YOU DID IT!" : "YOU WON!"}
              </div>
              {/* <div className="win_reward">{data.reward}</div> */}
              {!data.scratch ? (
                <div className="my-3 win_img img-with-text">
                  {data.img && (
                    <img
                      src={data.img}
                      alt="reward"
                      className={`img-fluid ${
                        wonRewards?.prize_type === "Major" && "img_for_peso"
                      }`}
                    />
                  )}
                  {wonRewards?.prize_type === "Major" && (
                    <div className="fw-medium text-white-color peso_img_text">
                      {data.reward}
                    </div>
                  )}
                </div>
              ) : (
                // <ScratchCard
                //   width={300}
                //   height={300}
                //   image={Images.scratch}
                //   finishPercent={60}
                // >
                <>
                  <img
                    src={wonRewards.prize_image}
                    alt="reward"
                    className={`img-fluid`}
                  />

                  {/* {data.rewardDesc && (
                  <div className="win_reward fs-3">{data.rewardDesc}</div>
                )} */}
                </>
                // </ScratchCard>
              )}
            </div>
          )}
          {gameType === "play" || gameType === "learn" ? (
            <div
              className="d-flex justify-content-between align-items-center w-100"
              style={{ padding: "0px 14px" }}
            >
              <h2>WIN:</h2>
              <h2>{lastNumOfWinsByUser + 1 + " / " + numOfGamesForRewards}</h2>
              <button className="reload-button" onClick={() => reStartGame()}>
                {reloadIcon}
              </button>
            </div>
          ) : (
            <div className="mt-auto d-flex justify-content-center align-items-center w-100">
              <button className="reload-button" onClick={() => reStartGame()}>
                {reloadIcon}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Won;
