import React from "react";
import { rocketIcon, storeIcon, ticketIcon, userIcon } from "../../icons/icon";
import { Badge, Container, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { reqToUserLogout } from "../../store/slice/userSlice";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { Images } from "../../images";

const Header = () => {
  const dispatch = useDispatch();

  const userReducer = useSelector((state) => state.user);
  const { userDetail } = userReducer;

  const handleLogout = () => {
    dispatch(reqToUserLogout());
  };

  return (
    <header className="fixed-top">
      <Navbar expand="true" className="fb_navbar">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" disabled />
        <Navbar.Collapse id="responsive-navbar-nav" className="">
          <nav className="">
            {/* <Link to="/boost" className="nav-link rocket_icon">
              {rocketIcon}
            </Link> */}
            {/* <a href="/broadcast" className="nav-link broadcast_icon">
              {broadcastIcon}
            </a> */}
            <div className="nav-link">
              <Link to="/home" className="nav-link ticket_icon">
                Home
              </Link>
            </div>
            <div className="nav-link">
              <Link to="/broadcast" className="point_text text-decoration-none">
                {userDetail.total_point ? userDetail.total_point : "0"} pts
              </Link>
            </div>
            <Link to="/store" className="nav-link">
              {storeIcon}
            </Link>
            <Link className="nav-link ticket_icon pe-none" disabled={true}>
              {ticketIcon}
              <div className="ms-2 tickets">
                {userDetail.total_ticket ? userDetail.total_ticket : "0"}
              </div>
            </Link>
            <Link to="/live" className="nav-link">
              <img src={Images.liveIcon} alt="liveIcon" className="ft_icon" />
              <small className="d-block">LIVE</small>
            </Link>
            <Link to="/broadcast" className="nav-link">
              <img src={Images.videosIcon} alt="liveIcon" className="ft_icon" />
              <small className="d-block">BROADCAST</small>
            </Link>
          </nav>
        </Navbar.Collapse>
        <Link to="/" className="text-decoration-none d-inline-block">
          <img
            // src={Images.logoSigla}
            src={Images.newHeaderLogo}
            alt="logoSigla"
            className=""
            height={30}
          />
        </Link>
        <Dropdown className="user_icon" align="end">
          <Dropdown.Toggle className="p-0 bg-transparent border-0 position-relative">
            {userIcon}
            <Badge bg="danger" className="rounded-circle position-absolute">
              1
            </Badge>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link to="/profile">
              <Dropdown.Item as="div">Profile</Dropdown.Item>
            </Link>
            <Dropdown.Item as="div" disabled>
              <Link to="/notification">Notifications</Link>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => handleLogout()}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar>
    </header>
  );
};

export default Header;
