import React from "react";
import { Link } from "react-router-dom";
import { Images } from "../../images";

const LivenessLevel = ({
  cardObj,
  benefitD,
  benefitState,
  nextToCongratsHandler,
  finishClaimHandler,
}) => {
  return (
    <>
      <div className="liveness_level">
        {/* <div>
          <div className="mb-5">
            <img src={cardObj.image} alt="levelImg" className="img-fluid" />
          </div>
          <h2>{cardObj.title ? cardObj.title : ""}</h2>
          {cardObj.randomReward ? (
            <h2
              className={`my-5 fw-bold ${
                benefitState === "congrats" && "mt-0 "
              }`}
            >
              {cardObj.randomReward}
            </h2>
          ) : null}
          {cardObj.levelText ? (
            <h4 className="mt-5">{cardObj.levelText}</h4>
          ) : null}
        </div> */}
        <img
          src={Images.congretsImg}
          alt="levelImg"
          className="img-fluid w-100 h-100"
        />

        {benefitState === "congrats" ? (
          // <button
          //   onClick={() =>
          //     nextToCongratsHandler(
          //       "confirmReward",
          //       benefitD.earn_ticket,
          //       benefitD.earn_point
          //     )
          //   }
          //   className="level-btn"
          // >
          //   {cardObj.btnText}
          // </button>
          <div className="liveness-btn">
            <button
              onClick={() => finishClaimHandler()}
              className="btn btn-primary bigger-btn"
            >
              {cardObj.btnText}
            </button>
          </div>
        ) : (
          <button onClick={() => finishClaimHandler()} className="level-btn">
            {cardObj.btnText}
          </button>
        )}
      </div>
    </>
  );
};

export default LivenessLevel;
