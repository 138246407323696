import React from "react";
import {
  bingoIcon,
  joystickIcon,
  learnIcon,
  rocketIcon,
} from "../../icons/icon";
import { Link } from "react-router-dom";
import { routes } from "../../helpers/constants";
import { Images } from "../../images";

const Footer = () => {
  return (
    <>
      <footer className="fixed-bottom">
        <div className="fb_footer">
          <Link to={routes.benefitsPage}>
            <img
              src={Images.benifitIcon}
              alt="benifitIcon"
              className="ft_icon"
            />
            <small className="d-block">BENEFITS</small>
          </Link>
          <Link to={routes.prizesPage}>
            <img src={Images.prizesIcon} alt="prizesIcon" className="ft_icon" />
            <small className="d-block">PRIZES</small>
          </Link>
          {/* <Link to={routes.livePage}>
            <img src={Images.liveIcon} alt="liveIcon" className="ft_icon" />
            <small className="d-block">LIVE</small>
          </Link> */}
          <Link to={routes.activity}>
            <img src={Images.activityIcon} alt="prizesIcon" className="ft_icon" />
            <small className="d-block">ACTIVITIES</small>
          </Link>
          {/* <Link to={routes.broadcastpage}>
            <img src={Images.videosIcon} alt="liveIcon" className="ft_icon" />
            <small className="d-block">BROADCAST</small>
          </Link> */}
        </div>
      </footer>
    </>
  );
};

export default Footer;
