import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import Preloader from "../../GilasLoaders/PreLoader";
import TryAgain from "../TryAgain/TryAgain";
import Won from "../Win/Won";

import { Images } from "../../../images";
import christmasGiftVideo from "../../../images/christmasGiftRecording.mp4";
import ChristmasGiftGame from "../../Games/ChristmasGiftGame";

const ChristmasGift = ({
  gameType,
  gameState,
  onPlayGameHandler,
  preLoaderImg,
  onLoadGame,
  timeoutRef,
  breakTimeOut,
  iRef,
  showGame,
  onGameFinishHandler,
  reStartGame,
  postLoaderImg,
  wonRewards,
  lastNumOfWinsByUser,
  numOfGamesForRewards,
}) => {
  const [showPoster, setShowPoster] = useState(true);

  useEffect(() => {
    if (gameType === "learn") {
      const timer = setTimeout(() => {
        setShowPoster(false);
      }, 1500);
      return () => {
        clearTimeout(timer);
      };
    } else {
      setShowPoster(true);
    }
  }, [gameType]);

  return (
    <>
      {!gameState ? (
        <>
          <div
            // className="home_wrapper"
            onClick={() => onPlayGameHandler()}
          >
            <div className="screen_bg">
              <video
                autoPlay
                muted
                loop
                playsInline
                poster={Images.christmasGiftBg}
              >
                {showPoster ? (
                  <>
                    <img
                      src="your-poster-image.jpg"
                      alt="Poster"
                      className="poster_img"
                    />
                    <p>Loading...</p>
                  </>
                ) : (
                  <>
                    <source src={christmasGiftVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </>
                )}
              </video>
            </div>
            <div className="home_content">
              <div className="">
                <div className="play_home christmas_win">
                  <Button variant="primary" size="lg">
                    {"WIN"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : gameState === "start" && gameType === "learn" ? (
        <>
          {!showGame && (
            <Preloader
              preLoaderImg={preLoaderImg}
              onLoadGame={onLoadGame}
              timeoutRef={timeoutRef}
              breakTimeOut={breakTimeOut}
            />
          )}
          <div>
            <ChristmasGiftGame
              iRef={iRef}
              onGameFinishHandler={onGameFinishHandler}
            />
          </div>
        </>
      ) : gameState === "tryAgain" && gameType === "learn" ? (
        <TryAgain
          gameType={gameType}
          reStartGame={reStartGame}
          postLoaderImg={postLoaderImg}
          winMsg={"Win 5x and get this"}
          gameName={"Christmas Gift Tower"}
          lastNumOfWinsByUser={lastNumOfWinsByUser}
          numOfGamesForRewards={numOfGamesForRewards}
        />
      ) : (
        gameType === "learn" && (
          <Won
            reStartGame={reStartGame}
            wonRewards={wonRewards}
            gameName={"Christmas Gift Tower"}
            gameType={gameType}
            lastNumOfWinsByUser={lastNumOfWinsByUser}
            numOfGamesForRewards={numOfGamesForRewards}
          />
        )
      )}
    </>
  );
};

export default ChristmasGift;
