import React from "react";
import LivenessLevel from "../../../component/Common/LivenessLevel";
import { Images } from "../../../images";

const Congrats = ({
  benefitD,
  nextToCongratsHandler,
  benefitState,
  // FOR PROCESSING
  finishClaimHandler,
}) => {
  const cardObj =
    benefitD.type === "Cash"
      ? benefitState === "congrats"
        ? // ? {
          //     image: Images.confeti,
          //     title: `Congratulations! Please wait for 24 to 48 hours to receive your P${benefitD.amount} SC Monthly Allowance in your E-wallet.`,
          //     levelText: "",
          //     randomReward: "",
          //     btnText: "NEXT",
          //   }
          {
            image: Images.confeti2,
            title: `Congratulations!`,
            levelText: "We will notify you once the transfer is done.",
            randomReward: "You got P100.00",
            btnText: "OK",
          }
        : benefitState === "rewardEarned"
        ? {
            image: Images.ticket,
            title: `Congratulations! You earned ${
              benefitD.earn_ticket ? benefitD.earn_ticket : "0"
            } Tickets and  ${
              benefitD.earn_point ? benefitD.earn_point : "0"
            } Points.`,
            levelText: "Use tickets to play BINGO and points to buy in Store.",
            randomReward: "",
            btnText: "OK",
          }
        : benefitState === "randomReward"
        ? {
            image: Images.ticket,
            title: "Congratulations! You earned",
            levelText: "Use tickets to play BINGO",
            randomReward: "1 Ticket",
            btnText: "OK",
          }
        : {
            image: Images.transferMoney,
            title: "",
            levelText:
              "We’re now processing your P1,000.00 SC Monthly Allowance. We will notify you once the transfer is successful.",
            randomReward: "",
            btnText: "OK",
          }
      : // : benefitState === "congrats"
      // ? {
      //     image: Images.confeti,
      //     title: `Congratulations! Please wait for 24 to 48 hours to receive your Free ${benefitD.type}.`,
      //     levelText: "",
      //     randomReward: "",
      //     btnText: "NEXT",
      //   }
      benefitState === "congrats"
      ? {
          image: Images.confeti2,
          title: `Congratulations!`,
          levelText: "We will notify you once the transfer is done.",
          randomReward: "You got P100.00",
          btnText: "OK",
        }
      : benefitState === "rewardEarned"
      ? {
          image: Images.ticket,
          title: `Congratulations! You earned ${
            benefitD.earn_ticket ? benefitD.earn_ticket : "0"
          } Tickets and  ${
            benefitD.earn_point ? benefitD.earn_point : "0"
          } Points.`,
          levelText: "Use tickets to play BINGO and points to buy in Store.",
          randomReward: "",
          btnText: "OK",
        }
      : benefitState === "randomReward"
      ? {
          image: Images.ticket,
          title: "Congratulations! You earned",
          levelText: "Use tickets to play BINGO",
          randomReward: "1 Ticket",
          btnText: "OK",
        }
      : {
          image: benefitD.banner_image
            ? benefitD.banner_image
            : Images.transferMoney,
          title: "",
          levelText: `We’re now processing your Free ${benefitD.type}. We will notify you once the processing is successful.`,
          randomReward: "",
          btnText: "OK",
        };

  return (
    <>
      <div className="flex_wrapper p-3">
        <LivenessLevel
          cardObj={cardObj}
          benefitD={benefitD}
          benefitState={benefitState}
          nextToCongratsHandler={nextToCongratsHandler}
          finishClaimHandler={finishClaimHandler}
        />
      </div>
    </>
  );
};

export default Congrats;
