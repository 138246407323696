import React from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../component/Layout/Footer";

const BroadcastVideo = () => {
  const location = useLocation();
  const videoUrl = location?.state?.video?.video_url;

  return (
    <>
      <main className="main_content">
        <div className="flex_wrapper bg-black">
          <video width="100%" height="350" controls autoPlay>
            <source src={videoUrl ? videoUrl : ""} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default BroadcastVideo;
