import React, { useEffect } from "react";
import BoostCard from "../../component/Common/BoostCard";
import { useDispatch, useSelector } from "react-redux";
import { getBoostList } from "../../store/slice/boostSlice";
import Loader from "../../component/Layout/Loader";
import Footer from "../../component/Layout/Footer";
import NoData from "../../component/Common/NoDataFound/NoData";

const Boost = () => {
  const dispatch = useDispatch();

  const boostReducer = useSelector((state) => state.boost);
  const { boostList } = boostReducer;

  useEffect(() => {
    dispatch(getBoostList({}));
  }, []);

  return (
    <>
      <main className="main_content">
        {boostReducer.loader && <Loader />}
        <div className="boost_wrapper p-3">
          {boostList?.length === 0 ? (
            <NoData />
          ) : (
            boostList?.map((item, index) => {
              return <BoostCard key={index} item={item} />;
            })
          )}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Boost;
