import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Images } from "../../images";
import { useNavigate } from "react-router-dom";
import { routes } from "../../helpers/constants";
import { useSelector } from "react-redux";

const Home = () => {
  const naviagte = useNavigate();

  const navigateHandler = (navTo) => {
    if (navTo === "benefit") {
      naviagte(routes.benefitsPage);
    } else if (navTo === "prizes") {
      naviagte(routes.prizesPage);
    } else {
      naviagte(routes.activity);
    }
  };

  return (
    <>
      <main className={`main_content`}>
        <div className="flex_wrapper p-3">
          <div className="landing_page_wrapper w-100">
            <Row className="text-center">
              <Col xs={12}>
                <div
                  role="button"
                  className="home-card"
                  onClick={() => navigateHandler("benefit")}
                >
                  <img
                    src={Images.getBenefits}
                    alt="maritesLiveImg"
                    className="img-fluid"
                  />
                  <h2 className="mb-0 text-uppercase">Get Benefits</h2>
                </div>
              </Col>
              <Col xs={12}>
                <div
                  role="button"
                  className="home-card"
                  onClick={() => navigateHandler("prizes")}
                >
                  <img
                    src={Images.winPrizes}
                    alt="maritesLiveImg"
                    className="img-fluid"
                  />
                  <h2 className="mb-0 text-uppercase">Win Prizes</h2>
                </div>
              </Col>
              <Col xs={12}>
                <div
                  role="button"
                  className="home-card"
                  onClick={() => navigateHandler("activity")}
                >
                  <img
                    src={Images.joinActivities}
                    alt="totoyLiveImg"
                    className="img-fluid"
                  />
                  <h2 className="mb-0 text-uppercase">Join Activities</h2>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
