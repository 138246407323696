import React, { useRef, useState } from "react";
import { Images } from "../../../images";
import Webcam from "react-webcam";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../helpers/constants";
import moment from "moment";

const UploadUserSelfie = ({ handleCollectBenifit }) => {
  const webcamRef = useRef(null);
  const navigate = useNavigate();
  const [fileUrl, setFileUrl] = useState();
  const [webcamStatus, setWebcamStatus] = useState("off");

  // Check camera permission
  const checkSystemWebcam = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then((stream) => {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
        setWebcamStatus("on");
      })
      .catch((err) => {
        if (err.name === "NotAllowedError") {
          alert(
            "The user has denied camera permission. Please grant permission!"
          );
        } else {
          alert("Browser is not supporting camera");
        }
      });
  };

  // Handle capture images
  const handleCaptureImage = () => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    if (imageSrc) {
      const blobData = dataURLtoBlob(imageSrc);
      setFileUrl(blobData && URL.createObjectURL(blobData));
      setWebcamStatus("capture");
    }
  };

  const clear = () => {
    if (fileUrl) {
      setFileUrl("");
      setWebcamStatus("on");
    }
  };

  // Handle submit
  const onSubmitProcess = () => {
    handleCollectBenifit();
    navigate(routes.benefitsPage);
  };

  return (
    <div className="flex_wrapper p-3">
      {webcamStatus === "off" ? (
        <div className="selfie-box selfie-img">
          <img
            src={Images.selfieImg}
            alt="selfie-img"
            className="img-fluid w-100 h-100"
          />
          <button
            className=" btn btn-primary selfie-btn bigger-btn"
            onClick={() => checkSystemWebcam()}
          >
            TAP
          </button>
        </div>
      ) : webcamStatus === "on" ? (
        <>
          <div className="selfie-box px-2">
            <Webcam
              className="tc-modal-click-photo"
              ref={webcamRef}
              screenshotFormat="image/png"
            />
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary mb-2 bigger-btn"
              onClick={(e) => handleCaptureImage(e)}
            >
              Capture
            </button>
          </div>
        </>
      ) : webcamStatus === "capture" ? (
        <div className="selfie-box-wrapper">
          <div className="selfie-box px-2">
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                src={fileUrl && fileUrl}
                alt="selfie-img"
                className="img-fluid w-100"
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary me-2"
              onClick={() => setWebcamStatus("")}
            >
              Save
            </button>
            <button className="btn btn-secondary" onClick={() => clear()}>
              Erase
            </button>
          </div>
        </div>
      ) : (
        <div className="selfie-passed h-100 w-100">
          <h3 style={{ color: "green" }}>PASSED</h3>
          <div className="orange-bdr flex-grow-1 my-auto d-flex justify-content-center align-items-center h-100 passed-img">
            <img
              src={fileUrl && fileUrl}
              alt="selfie-img"
              className="img-fluid"
            />
          </div>
          <div className="pt-2">
            <p className="mb-0">
              Date: {moment().format("MMM D, YYYY h:mm:ss A")}
            </p>
            <p>Location: 745 Binondo, Manila</p>
            <button
              className="btn btn-primary bigger-btn"
              onClick={() => onSubmitProcess()}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadUserSelfie;
