import React from "react";
import BenifitReceiveDetails from "../../component/Common/BenifitReceiveDetails";
import { Images } from "../../images";

const RecieveDetails = () => {
  return (
    <>
      <main className={`main_content`}>
        <div className="flex_wrapper p-3">
          <BenifitReceiveDetails
            noteTime="2 mins ago"
            Icon={Images.transferMoney}
            title="Your SC P1,000.00 Monthly Allowance successfully sent"
            description="Your SC P1,000.00 Monthly Allowance successfully sent to your GCash Account #: 0912345678 with Transaction no.: 001239784 11-02-23 2:39 PM"
          />
        </div>
      </main>
    </>
  );
};

export default RecieveDetails;
