import React from "react";

const Footer2 = () => {
  return (
    <footer className="fixed-bottom">
      <div className="fb_footer"></div>
    </footer>
  );
};

export default Footer2;
