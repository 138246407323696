import React, { useEffect, useRef, useState } from "react";
import { Mousewheel, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import RotatePhone from "../../component/Common/Rotate/RotatePhone";
import Loader from "../../component/Layout/Loader";
import BackNav from "../../component/Common/BackNav";
import Header from "../../component/Layout/Header";
import Footer from "../../component/Layout/Footer";
import { reqToGetUserDetailById } from "../../store/slice/userSlice";
import { useNavigate } from "react-router";
import {
  reqForExtraWin,
  reqForGameResult,
  reqToplayGame,
} from "../../store/slice/gameSlice";
import Game3 from "../../component/Common/GameComponent/Game3";
import Game5 from "../../component/Common/GameComponent/Game5";
import ChristmasGift from "../../component/Common/GameComponent/ChristmasGift";

const Prizes = () => {
  const iRef = useRef(null);
  const timeoutRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userReducer = useSelector((state) => state.user);
  const gameReducer = useSelector((state) => state.game);

  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const [gameType, setGameType] = useState("learn");

  const [minScore, setMinScore] = useState(0);

  const [gameState, setGameState] = useState("");
  const [showGame, setShowGame] = useState(false);

  const [preLoaderImg, setPreLoaderImg] = useState("");
  const [postLoaderImg, setPostLoaderImg] = useState("");

  // WIN
  const [ticketModal, setTicketModal] = useState(false);
  const [noTicketModal, setNoTicketModal] = useState(false);

  const [wonRewards, setWonRewards] = useState("");
  const [breakTimeOut, setBreakTimeOut] = useState(false);

  // PLAY for LAST BATTLE
  const [lastNumOfWinsByUser, setLastNumOfWinsByUser] = useState(0);
  const [numOfGamesForRewards, setNumOfGamesForRewards] = useState(0);

  useEffect(() => {
    dispatch(reqToGetUserDetailById({}));
  }, []);

  const onPlayGameHandler = () => {
    setBreakTimeOut(false);
    const data = {
      game_type: gameType,
    };
    dispatch(
      reqToplayGame({
        data: data,
        cb: (res) => {
          if (res?.status) {
            setPreLoaderImg(res?.data?.pre_image);
            setPostLoaderImg(res?.data?.post_image);
            setMinScore(res?.data.score);
            if (gameType === "play" || gameType === "learn") {
              {
                setGameState("start");
                setLastNumOfWinsByUser(res?.data?.count);
                setNumOfGamesForRewards(res?.data?.max_play);
              }
            } else {
              setGameState("start");
            }
          }
        },
      })
    );
  };

  // FOR WIN
  const showTicketModal = () => {
    setTicketModal(true);
  };
  const showNoTicketModal = () => {
    setNoTicketModal(true);
  };
  const closeTicketModal = () => {
    setTicketModal(false);
  };
  const closeNoTicketModal = () => {
    setNoTicketModal(false);
  };
  //

  // FOR WIN
  const onPlayWinGameHandler = () => {
    setBreakTimeOut(false);
    const data = {
      game_type: "win",
      ticket: 1,
    };
    dispatch(
      reqToplayGame({
        data: data,
        cb: (res) => {
          if (res?.status) {
            setPreLoaderImg(res?.data?.pre_image);
            setPostLoaderImg(res?.data?.post_image);
            setGameState("start");
            setTicketModal(false);
          } else {
            setTicketModal(false);
            showNoTicketModal();
          }
        },
      })
    );
  };
  //

  const showWinORTryAgain = (resultType) => {
    const timer = setTimeout(() => {
      if (resultType === "win") {
        setGameState("won");
      } else {
        setGameState("tryAgain");
      }
      const data = {
        result: resultType,
        game_type: gameType,
      };

      if (
        gameType === "play" &&
        lastNumOfWinsByUser !== 0 &&
        lastNumOfWinsByUser >= numOfGamesForRewards
      ) {
        if (resultType === "win") {
          dispatch(
            reqForExtraWin({
              data: {
                game_type: "play",
              },
              cb: (res) => {
                setWonRewards("noRewards");
              },
            })
          );
        }
      } else if (
        gameType === "learn" &&
        lastNumOfWinsByUser !== 0 &&
        lastNumOfWinsByUser >= numOfGamesForRewards
      ) {
        if (resultType === "win") {
          dispatch(
            reqForExtraWin({
              data: {
                game_type: "learn",
              },
              cb: (res) => {
                setWonRewards("noRewards");
              },
            })
          );
        }
      } else {
        dispatch(
          reqForGameResult({
            data: data,
            cb: (res) => {
              dispatch(reqToGetUserDetailById({}));
              setWonRewards(res?.data);
            },
          })
        );
      }
      setShowGame(false);
    }, 4000);
    return () => clearTimeout(timer);
  };

  const onGameFinishHandler = () => {
    if (gameType === "play") {
      // FOR LAST BATTLE
      window.onmessage = (e) => {
        if (e?.data === "play_clicked") {
          document
            .getElementById("whack-a-pest-iframe")
            .contentWindow.postMessage(
              //
              // {
              //   last_battle_number_of_wins: lastNumOfWinsByUser, // number of game won by logged-in user
              //   last_battle_maximum_wins: numOfGamesForRewards, // total required game wins
              //   last_battle_win_condition: minScore, //required score to win the game
              // },
              {
                whack_pest_win_condition: minScore, //required score to win the game
              },
              "*"
            );
        }
        // for null or undefined
        if (e?.data?.type || typeof e?.data === "string" || !e?.data) return;
        // for result

        if (e?.data?.is_win || e?.data?.is_win === false) {
          let userScore = e?.data?.is_win;
          const resultType = userScore ? "win" : "lose";
          showWinORTryAgain(resultType);
        }
      };

      // FOR SANTAA
      // window.onmessage = (e) => {
      //   // for minScore
      //   if (e?.data === "play_clicked") {
      //     document
      //       .getElementById("santa-iframe")
      //       .contentWindow.postMessage({ santa_win_count: minScore }, "*");
      //   }
      //   // for null or undefined
      //   if (e?.data?.type || typeof e?.data === "string" || !e?.data) return;
      //   // for result

      //   if (e?.data?.santa_gift_deliver || e?.data?.santa_gift_deliver === 0) {
      //     let userScore = e.data.santa_gift_deliver;
      //     const resultType = userScore >= minScore ? "win" : "lose";
      //     showWinORTryAgain(resultType);
      //   }
      // };
    } else if (gameType === "learn") {
      window.onmessage = (e) => {
        //// for minScore
        if (e?.data === "go_to_broadcast_clicked") {
          navigate("/broadcast");
        }
        /// quiz_win_count
        if (e?.data === "play_clicked") {
          document
            .getElementById("christmas-gift-iframe")
            .contentWindow.postMessage(
              { christ_gift_win_condition: minScore },
              "*"
            );
        }
        //// for null or undefined
        if (!e?.data) return;
        // for result
        if (e?.data?.is_win || e?.data?.is_win === false) {
          let userScore = e?.data?.is_win;
          const resultType = userScore ? "win" : "lose";
          showWinORTryAgain(resultType);
        }
      };
      // FOR WIN
    } else {
      window.onmessage = (e) => {
        // for null or undefined
        if (e?.data?.type || typeof e?.data === "string" || !e?.data) return;
        // for result
        if (e?.data?.bingo_win_status || e?.data?.bingo_win_status === false) {
          let winStatus = e.data.bingo_win_status;
          const resultType = winStatus ? "win" : "lose";
          showWinORTryAgain(resultType);
        }
      };
    }
    //
  };

  const reStartGame = () => {
    if (gameType === "play" || gameType === "learn") {
      setBreakTimeOut(false);
      const data = {
        game_type: gameType,
      };
      dispatch(
        reqToplayGame({
          data: data,
          cb: (res) => {
            if (res?.status) {
              setPreLoaderImg(res?.data?.pre_image);
              setPostLoaderImg(res?.data?.post_image);
              setMinScore(res?.data.score);
              setGameState("start");
              if (gameType === "play" || gameType === "learn") {
                setLastNumOfWinsByUser(res?.data?.count);
                setNumOfGamesForRewards(res?.data?.max_play);
              }
              //   window.santa_win_count = res?.data.score;
            }
          },
        })
      );
    } else {
      setBreakTimeOut(false);
      setTicketModal(true);
      setGameState("");
      setShowGame(false);
    }
  };

  const goBackBtnShow = () => {
    if (
      (!showGame && !gameState) ||
      (!showGame && gameState) ||
      (showGame && gameState)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const goBack = () => {
    setBreakTimeOut(true);
    dispatch(reqToGetUserDetailById({}));
    if (
      gameState === "start" ||
      gameState === "tryAgain" ||
      gameState === "won" ||
      gameState === "alreadyPlayed"
    ) {
      setGameState("");
      setMinScore("");
      setShowGame(false);
    }
  };

  // on  swipp hanlder
  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    setGameType(
      activeIndex === 0 ? "learn" : activeIndex === 1 ? "play" : "win"
    );
    setMinScore(0);
    setGameState("");
    setShowGame(false);
    setPreLoaderImg("");
    setPostLoaderImg("");
    setWonRewards("");
    setBreakTimeOut(false);

    // FOR WIN
    setTicketModal(false);
    setNoTicketModal(false);
  };

  const onLoadGame = () => {
    timeoutRef.current = setTimeout(() => {
      if (!breakTimeOut) {
        setShowGame(true);
      }
    }, 2500);
  };

  return (
    <>
      {!showGame && isLandscape && isMobile ? (
        <RotatePhone type="portrait" />
      ) : (
        <>
          <main className={`main_content ${showGame && "game-main-content"}`}>
            {(gameReducer.loader || userReducer.loader) && <Loader />}
            {!gameState ? (
              <Header />
            ) : (
              !showGame && (
                <BackNav goBack={goBack} goBackBtnShow={goBackBtnShow} />
              )
            )}
            <div className="home_wrapper">
              <Swiper
                direction={"vertical"}
                slidesPerView={1}
                spaceBetween={30}
                mousewheel={true}
                pagination={
                  showGame ||
                  gameState === "start" ||
                  gameState === "tryAgain" ||
                  gameState === "won"
                    ? false
                    : {
                        clickable: true,
                      }
                }
                allowSlidePrev={
                  showGame ||
                  gameState === "start" ||
                  gameState === "tryAgain" ||
                  gameState === "won"
                    ? false
                    : true
                }
                allowSlideNext={
                  showGame ||
                  gameState === "start" ||
                  gameState === "tryAgain" ||
                  gameState === "won"
                    ? false
                    : true
                }
                allowTouchMove={
                  showGame ||
                  gameState === "start" ||
                  gameState === "tryAgain" ||
                  gameState === "won"
                    ? false
                    : true
                }
                modules={[Mousewheel, Pagination]}
                className={"gilas-slider"}
                onSlideChange={handleSlideChange}
              >
                <SwiperSlide>
                  {/* <Games1
                    gameType={gameType}
                    gameState={gameState}
                    onPlayGameHandler={onPlayGameHandler}
                    preLoaderImg={preLoaderImg}
                    onLoadGame={onLoadGame}
                    timeoutRef={timeoutRef}
                    breakTimeOut={breakTimeOut}
                    isLandscape={isLandscape}
                    iRef={iRef}
                    showGame={showGame}
                    onGameFinishHandler={onGameFinishHandler}
                    isMobile={isMobile}
                    reStartGame={reStartGame}
                    postLoaderImg={postLoaderImg}
                    wonRewards={wonRewards}
                  /> */}
                  <ChristmasGift
                    gameType={gameType}
                    gameState={gameState}
                    onPlayGameHandler={onPlayGameHandler}
                    preLoaderImg={preLoaderImg}
                    onLoadGame={onLoadGame}
                    timeoutRef={timeoutRef}
                    breakTimeOut={breakTimeOut}
                    isLandscape={isLandscape}
                    iRef={iRef}
                    showGame={showGame}
                    onGameFinishHandler={onGameFinishHandler}
                    isMobile={isMobile}
                    reStartGame={reStartGame}
                    postLoaderImg={postLoaderImg}
                    wonRewards={wonRewards}
                    lastNumOfWinsByUser={lastNumOfWinsByUser}
                    numOfGamesForRewards={numOfGamesForRewards}
                  />
                </SwiperSlide>
                {/* <SwiperSlide>
                  <Game5
                    gameType={gameType}
                    gameState={gameState}
                    iRef={iRef}
                    showGame={showGame}
                    onPlayGameHandler={onPlayGameHandler}
                    onGameFinishHandler={onGameFinishHandler}
                    preLoaderImg={preLoaderImg}
                    onLoadGame={onLoadGame}
                    timeoutRef={timeoutRef}
                    breakTimeOut={breakTimeOut}
                    reStartGame={reStartGame}
                    postLoaderImg={postLoaderImg}
                    lastNumOfWinsByUser={lastNumOfWinsByUser}
                    numOfGamesForRewards={numOfGamesForRewards}
                    wonRewards={wonRewards}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Game3
                    gameType={gameType}
                    showGame={showGame}
                    gameState={gameState}
                    onPlayWinGameHandler={onPlayWinGameHandler}
                    isLandscape={isLandscape}
                    isMobile={isMobile}
                    onGameFinishHandler={onGameFinishHandler}
                    iRef={iRef}
                    breakTimeOut={breakTimeOut}
                    noTicketModal={noTicketModal}
                    closeNoTicketModal={closeNoTicketModal}
                    closeTicketModal={closeTicketModal}
                    showTicketModal={showTicketModal}
                    preLoaderImg={preLoaderImg}
                    onLoadGame={onLoadGame}
                    timeoutRef={timeoutRef}
                    reStartGame={reStartGame}
                    postLoaderImg={postLoaderImg}
                    wonRewards={wonRewards}
                    ticketModal={ticketModal}
                  />
                </SwiperSlide> */}
              </Swiper>
            </div>
          </main>
          {(!showGame || !gameState) && <Footer />}
        </>
      )}
    </>
  );
};

export default Prizes;
