import React, { useEffect, useState } from "react";
import PosterVideo from "./PosterVideo";
import BenefitPreLoader from "../../GilasLoaders/BenefitPreLoader";
import CollectBenifits from "./CollectBenifits";
import Congrats from "./Congrats";
import UploadUserImage from "./UploadUserImage";
import UploadUserSelfie from "./UploadUserSelfie";

const BenefitItem = ({
  benefitState,
  setBenefitState,
  benefitTab,
  benefitD,
  preLoaderStateHandler,
  onLoadFinish,
  timeoutRef,
  breakTimeOut,
  benefitClaimType,
  collectClaimHandler,
  nextToCongratsHandler,
  finishClaimHandler,
  benefitDueDay,
  handleCollectBenifit,
}) => {
  const [showPoster, setShowPoster] = useState(true);

  useEffect(() => {
    if (benefitTab === "Cash" && benefitTab === benefitD.type) {
      const timer = setTimeout(() => {
        setShowPoster(false);
      }, 1500);

      return () => {
        clearTimeout(timer);
      };
    } else {
      setShowPoster(true);
    }
  }, [benefitTab]);

  return (
    <>
      {!benefitState ? (
        <PosterVideo
          benefitTab={benefitTab}
          benefitD={benefitD}
          showPoster={showPoster}
          preLoaderStateHandler={preLoaderStateHandler}
        />
      ) : benefitState === "loader" && benefitTab === benefitD.type ? (
        <BenefitPreLoader
          preLoaderImg={benefitD.pre_loader_image && benefitD.pre_loader_image}
          onLoadFinish={onLoadFinish}
          timeoutRef={timeoutRef}
          breakTimeOut={breakTimeOut}
        />
      ) : (benefitState === "collect" || benefitState === "due") &&
        benefitTab === benefitD.type ? (
        <CollectBenifits
          benefitD={benefitD}
          benefitClaimType={benefitClaimType}
          collectClaimHandler={collectClaimHandler}
          //FOR DUE DAYS
          benefitDueDay={benefitDueDay}
          nextToCongratsHandler={nextToCongratsHandler}
        />
      ) : benefitState === "uploadeImage" && benefitTab === benefitD.type ? (
        <UploadUserImage setBenefitState={setBenefitState} />
      ) : benefitState === "uploadeSelfie" && benefitTab === benefitD.type ? (
        <UploadUserSelfie handleCollectBenifit={handleCollectBenifit} />
      ) : (benefitState === "congrats" ||
          benefitState === "rewardEarned" ||
          benefitState === "randomReward" ||
          benefitState === "processing") &&
        benefitTab === benefitD.type ? (
        <Congrats
          benefitD={benefitD}
          nextToCongratsHandler={nextToCongratsHandler}
          benefitState={benefitState}
          // FOR PROCESSING
          finishClaimHandler={finishClaimHandler}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default BenefitItem;
