import { toast } from "react-toastify";
const moment = require("moment-timezone");
export const defaultTimeZone = moment.tz.guess();

// One time toast message show //
const toastList = new Set();
const MAX_TOAST = 2;

export function notify(message, messageType, autoCloseTime) {
  if (toastList.size < MAX_TOAST) {
    let id;
    if (messageType === "success") {
      id = toast.success(message, {
        // I'm using the onClose hook here to remove the id
        // from the set
        autoClose: autoCloseTime ? autoCloseTime : 1500,
        onClose: () => toastList.delete(id),
      });
    } else {
      id = toast.error(message, {
        // I'm using the onClose hook here to remove the id
        // from the set
        autoClose: autoCloseTime ? autoCloseTime : 1500,
        onClose: () => toastList.delete(id),
      });
    }
    toastList.add(id);
  }
}

export const generateAndCheckRandom = () => {
  const fixNumber = 3;
  const randomNumber = Math.floor(Math.random() * 5) + 1;

  return randomNumber === fixNumber;
};
