import React, { useEffect, useState } from "react";
import { Images } from "../../../images";
import {
  getUploadedImageData,
  reqToAddMediaFile,
} from "../../../store/slice/benefitSlice";
import { useDispatch, useSelector } from "react-redux";

const UploadUserImage = (props) => {
  const { setBenefitState } = props;
  const dispatch = useDispatch();

  const benefitReducer = useSelector((state) => state.benefit.uploadImageData);

  const [fileData, setFileData] = useState();
  const [uploadDocData, setUploadDocData] = useState({
    image: "",
    gCaseNumber: "",
  });

  useEffect(() => {
    if (benefitReducer.gCaseNumber && benefitReducer.image) {
      const inputText = benefitReducer.gCaseNumber.replace(/-/g, "");
      const inputText1 = inputText.replaceAll(" ", "");
      const formattedText = addHyphens(inputText1, 4);
      setUploadDocData({
        image: benefitReducer.image,
        gCaseNumber: formattedText,
      });
      setFileData(benefitReducer.fileData);
    }
  }, [benefitReducer]);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setFileData(fileUploaded);
  };

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append("file", fileData && fileData);
    dispatch(
      reqToAddMediaFile({
        data: formData,
        onSuccess: (response) => {
          if (response && response?.data?.length > 0) {
            let imagesUrl = response?.data[0]?.imageUrlBigUrl;
            setUploadDocData({ ...uploadDocData, image: imagesUrl });
            let newSerial_no = uploadDocData.gCaseNumber.replaceAll("-", "");
            dispatch(
              getUploadedImageData({
                image: imagesUrl,
                fileData: fileData,
                gCaseNumber: newSerial_no,
              })
            );
            setBenefitState("uploadeSelfie");
          }
        },
      })
    );
  };

  const handleOnChange = (event) => {
    const inputText = event.replace(/[^0-9]/g, "");
    const formattedText = addHyphens(inputText, 4);
    setUploadDocData({
      ...uploadDocData,
      gCaseNumber: formattedText,
    });
  };

  function addHyphens(inputString, interval) {
    const regex = new RegExp(`(.{1,${interval}})`, "g");
    return inputString.replace(regex, "$1-").slice(0, -1);
  }

  return (
    <div
      className="flex_wrapper justify-content-between p-3"
      style={{ margin: "30px 0px" }}
    >
      <div
        className={`cm-file-upload ${
          ((fileData || uploadDocData.image) &&
            uploadDocData.gCaseNumber?.length >= 13) ||
          true
            ? "h-80"
            : null
        }`}
      >
        <input
          type="file"
          id="file"
          name="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={(e) => handleChange(e)}
        />
        <label htmlFor="file" className="">
          <div
            className={`d-flex align-items-center justify-content-center p-2 ${
              fileData || uploadDocData.image ? "fill-img" : null
            }`}
          >
            <img
              src={
                fileData
                  ? URL.createObjectURL(fileData)
                  : uploadDocData.image
                  ? uploadDocData.image
                  : Images.cameraImg
              }
              alt="cameraIcon"
              width={200}
            />
          </div>
          {/* {!fileData && !uploadDocData.image && (
            <>
              <h6 className="mb-0 mt-3">Tap to upload</h6>
              <h3 className="mb-0">Senior Citizen ID</h3>
            </>
          )} */}
        </label>
      </div>

      <div
        className="row align-items-center mt-3"
        style={{ marginBottom: "20px" }}
      >
        <div className="col-4">
          <h6 className="d-flex align-items-center">
            <span className="text-primary pe-1 fw-bold">GCash</span>
            <span>Number:</span>
          </h6>
        </div>
        <div className="col-8">
          <input
            type="tel"
            className="form-control"
            placeholder="0999-9999-999"
            value={uploadDocData.gCaseNumber}
            maxLength={13}
            onChange={(e) => handleOnChange(e.target.value)}
          />
        </div>
      </div>
      {(fileData || uploadDocData.image) &&
        uploadDocData.gCaseNumber?.length >= 13 && (
          <button
            className="medium-size-btn  btn btn-primary mt-3"
            onClick={() => handleFileUpload()}
          >
            NEXT
          </button>
        )}
    </div>
  );
};

export default UploadUserImage;
