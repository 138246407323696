import React from "react";
import { Card } from "react-bootstrap";

const BenifitReceiveDetails = (props) => {
  return (
    <>
      <Card className="orange_outline_card benifitReceive_card px-3 py-4">
        <div className="d-flex justify-content-between bdr-bottom pb-2">
          <img src={props.Icon} alt="icon" className="pe-2 flex-shrink-0 img-fluid"/>
          <div className="flex-grow-1">
            <small className="d-block text-end text-primary">{props.noteTime}</small>
            <Card.Text className="m-0 text-start fw-medium">{props.title}</Card.Text>
          </div>
        </div>
        <Card.Body className="py-2 px-0 text-start">
          <div className="fw-medium">
            <Card.Text className="m-0">{props.description}</Card.Text>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default BenifitReceiveDetails;
