import React from "react";
import { Button, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Images } from "../../images";

const BroadcastCard = (props) => {
  const { item } = props;

  return (
    <>
      <Card className="orange_card broadcast_card px-3 py-4">
        <div className="pe-3">
          <img src={item && item.image_url ? item.image_url : ""} alt="icon" />
        </div>
        <Card.Body className="p-0 text-center">
          <div className="mb-3">
            <Card.Text className="m-0">{item.description}</Card.Text>
          </div>

          <Link
            to={`/broadcastVideo?video_url=${item.video_url}`}
            state={{ video: item }}
          >
            <Button variant="light" className="white_btn">
              WATCH
            </Button>
          </Link>
        </Card.Body>
      </Card>
    </>
  );
};

export default BroadcastCard;
