export const routes = {
  loginPage: "/login",

  mainPage: "/home",

  homepage: "/",
  learnpage: "/learn",
  preloader: "/preloader",
  winpage: "/win",
  boostpage: "/boost",
  broadcastpage: "/broadcast",
  broadcastVideopage: "broadcastVideo",
  storepage: "/store",
  profilepage: "/profile",
  notificationpage: "/notification",

  benefitsPage: "/benefits",
  prizesPage: "/prizes",
  livePage: "/live",

  benifitPreloader: "/benifitPreloader",
  collectBenifits: "/collectBenifits",
  verifyBenifits: "/verifyBenifits",
  livePreloader: "/livePreloader",
  liveGame: "/liveGame",
  activity: "/activity",
  activityPreloader: "/activityPreloader",
  activityDetails: "/activityDetails",

  tutorialpage: "/tutorial",

  signuppage: "/signup",

  all: "*",
};

export const apiendpoints = {
  login: "/user/login",
  getUsers: "/user",

  getbroadcastList: "/broadcast/list",

  playGame: "/game/play-game",
  gameResult: "/game/game-result",

  extraWin: "/game/extra-win",
};
