import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { toast } from "react-toastify";

export const getStoreData = createAsyncThunk("getStoreData", async (data) => {
  try {
    const response = await Axios.get(
      `/store/list?type=${data.type || ""}`,
      authHeaders()
    );
    return response;
  } catch (error) {
    return error;
  }
});

export const byStore = createAsyncThunk("byStore", async (data) => {
  try {
    const response = await Axios.post(`/store/buy`, data.data, authHeaders());
    if (response.status === 200 || response.status === 201) {
      toast.success("Purchased!", { autoClose: 3000 });
    }
    if (response.status === 202) {
      toast.warn("You do not have sufficient points!", { autoClose: 3000 });
    }
    if (data.cb) {
      data.cb(response.data && response.data);
    }
    return response;
  } catch (error) {
    return error;
  }
});

// Create initialState
const initialState = {
  loader: false,
  error: "",
  byStore: "",
  storeData: [],
};

// Create slice
const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Store Data
    builder.addCase(getStoreData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getStoreData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.storeData = action?.payload?.data?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(getStoreData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // by Store
    builder.addCase(byStore.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(byStore.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.byStore = action?.payload;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(byStore.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default storeSlice.reducer;
