import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";

//Get getBenefitList
export const getBenefitList = createAsyncThunk("getBenefitList", async () => {
  try {
    const response = await Axios.get(`/benefit/list`, authHeaders());
    return response;
  } catch (error) {
    return error;
  }
});

// For reqToClaimBenefit
export const reqToClaimBenefit = createAsyncThunk(
  "reqToClaimBenefit",
  async (data) => {
    try {
      const response = await Axios.post(
        `/benefit/claim`,
        data.data,
        authHeaders()
      );
      if (data.cb) {
        data.cb(response?.data && response.data);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

// For collectBenefit
export const collectBenefit = createAsyncThunk(
  "collectBenefit",
  async (data) => {
    try {
      const response = await Axios.post(
        `/benefit/collect`,
        data.data,
        authHeaders()
      );
      if (data.cb) {
        data.cb(response?.data && response.data);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

// For Random Tickets Rewards
export const getRandomTicketReward = createAsyncThunk(
  "getRandomTicketReward",
  async (data) => {
    try {
      const response = await Axios.post(
        `/benefit/earn-ticket`,
        data.data,
        authHeaders()
      );
      if (data.cb) {
        data.cb(response?.data && response.data);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

// For Random Tickets Rewards
export const getGuarantedRewards = createAsyncThunk(
  "getGuarantedRewards",
  async (data) => {
    try {
      const response = await Axios.post(
        `/benefit/earn-ticket-point`,
        data.data,
        authHeaders()
      );
      if (data.cb) {
        data.cb(response?.data && response.data);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to store images
export const reqToAddMediaFile = createAsyncThunk(
  "reqToAddMediaFile",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(`/user/media`, data, authHeaders());
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to collect benifit
export const reqToCollectBenifit = createAsyncThunk(
  "reqToCollectBenifit",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        `/benefit/collect-v2`,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

const benefitSlice = createSlice({
  name: "benefit",
  initialState: {
    benefitList: [],
    claimBenefit: {},
    collectBenefit: {},
    randomTicket: {},
    guarantedRewards: {},
    error: "",
    loader: false,
    uploadImageData: {},
  },
  reducers: {
    getUploadedImageData: (state, action) => {
      state.uploadImageData = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Boost List
    builder.addCase(getBenefitList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getBenefitList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.benefitList = action?.payload?.data?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(getBenefitList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // reqToClaimBenefit
    builder.addCase(reqToClaimBenefit.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToClaimBenefit.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.claimBenefit = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data.message, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToClaimBenefit.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // collectBenefit
    builder.addCase(collectBenefit.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(collectBenefit.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.collectBenefit = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(collectBenefit.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // getRandomTicketReward
    builder.addCase(getRandomTicketReward.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getRandomTicketReward.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.randomTicket = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(getRandomTicketReward.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // getGuarantedRewards
    builder.addCase(getGuarantedRewards.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getGuarantedRewards.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.guarantedRewards = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(getGuarantedRewards.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // reqToAddMediaFile
    builder.addCase(reqToAddMediaFile.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddMediaFile.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToAddMediaFile.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // reqToCollectBenifit
    builder.addCase(reqToCollectBenifit.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToCollectBenifit.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToCollectBenifit.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export const { getUploadedImageData } = benefitSlice.actions;
export default benefitSlice.reducer;
