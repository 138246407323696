import React from "react";
import { Images } from "../../../images";
import video from "../../../images/coverVideo_MonthlyAllowance.mp4";
import { Button } from "react-bootstrap";

const PosterVideo = ({
  benefitTab,
  benefitD,
  showPoster,
  preLoaderStateHandler,
}) => {
  const defaultImage =
    benefitTab === "Cash"
      ? Images.coverMonthlyAllowance
      : benefitTab === "Groceries"
      ? Images.coverFreegroceries
      : Images.coverFreemedicines;

  return (
    <div
      role="button"
      onClick={() => preLoaderStateHandler(benefitD)}
      className="gilas-slide"
    >
      <div className="screen_bg benefit_poster_container">
        <video
          autoPlay
          muted
          loop
          playsInline
          poster={benefitD && benefitD.image ? benefitD.image : defaultImage}
        >
          {showPoster ? (
            <>
              <img
                src={benefitD && benefitD.image ? benefitD.image : defaultImage}
                alt="Poster"
                className="poster_img"
              />
              <p>Loading...</p>
            </>
          ) : (
            <>
              <source
                src={benefitD.video ? benefitD.video : video}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </>
          )}
        </video>
      </div>
      <div className="home_content">
        <div className="">
          <div className="play_home benefit_screen_btn">
            <Button className="medium-size-btn " variant="primary" size="lg">
              GET
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosterVideo;
