import React from "react";
import noDataFound from "../../../images/noDataFound.png";

const NoData = () => {
  return (
    <div className="no-data-found">
      <img src={noDataFound} alt="no-data" />
    </div>
  );
};

export default NoData;
