import React from "react";
import { Button, Card } from "react-bootstrap";

const NotificationCard = (props) => {
  return (
    <>
      <Card className="orange_card notification_card px-3 py-4">
        <div className="pe-3">{props.Icon}</div>
        <Card.Body className="p-0">
          {props.noticeTime ? (
            <div className="text-end text-primary">
              <small>{props.noticeTime}</small>
            </div>
          ) : null}
          <div className="mb-3">
            <Card.Text className="m-0">{props.title}</Card.Text>
          </div>
          {props.btnText ? (
            <div className="text-end">
              <Button variant="light" className="white_btn">
                {props.btnText}
              </Button>
            </div>
          ) : null}
        </Card.Body>
      </Card>
    </>
  );
};

export default NotificationCard;
