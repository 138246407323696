import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Verify = () => {
  const navigate = useNavigate();

  const navigateToNextPage = () => {
    setTimeout(() => {
      navigate("/livenessLevel1");
    }, 3000);
  };

  useEffect(() => {
    navigateToNextPage();
  }, []);
  return (
    <main className={`main_content`}>
      <div className="flex_wrapper">
        <h2>Verifying...</h2>
      </div>
    </main>
  );
};

export default Verify;
