import React from "react";

const ChristmasGiftGame = ({ iRef, onGameFinishHandler }) => {
  const timeStamp = new Date().getTime();

  return (
    <div className="game-content">
      <div className="game-initailize">
        <iframe
          width="100%"
          id="christmas-gift-iframe"
          height=""
          src={`${process.env.REACT_APP_CHRISTMAS_GIFT_TOWER_GAME_BASE_URL}index.html?${timeStamp}`}
          ref={(component) => {
            iRef.current = component;
          }}
          onLoad={() => onGameFinishHandler()}
        />
      </div>
    </div>
  );
};

export default ChristmasGiftGame;
