import React, { useState } from "react";
import { Autoplay, Mousewheel, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { Images } from "../../images";
import { Button } from "react-bootstrap";
// import required modules

const Tutorial = () => {
  const [lastTab, setLastTab] = useState(false);

  const handleSlideChange = (swiper) => {
    const isLastSlide = swiper.isEnd;
    isLastSlide ? setLastTab(true) : setLastTab(false);
  };

  return (
    <>
      <main className={`main_content`}>
        <div className="home_wrapper p-3">
          <Swiper
            direction={"horizontal"}
            slidesPerView={1}
            spaceBetween={30}
            mousewheel={true}
            onSlideChange={handleSlideChange}
            pagination={{
              clickable: true,
            }}
            modules={[Mousewheel, Pagination, Autoplay, Navigation]}
            className="gilas-slider tutorial-slider"
            autoplay={{
              delay: !lastTab ? 2500 : 100000000000,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide>
              <div className="gilas-slide">
                <h1 className="text-center mb-5" style={{ fontSize: "35px" }}>
                  What <br />{" "}
                  <span className="text-primary fw-bold ">can you do</span>
                  <br />
                  in
                  <span className="text-secondary fw-bold"> PAMASKO App</span>
                </h1>
                <img
                  src={Images.whatYouCanDo}
                  alt="maritesLiveImg"
                  className="img-fluid w-75"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gilas-slide">
                <img
                  src={Images.getBenefits1}
                  alt="maritesLiveImg"
                  className="img-fluid"
                />
                <img
                  src={Images.getBenefits}
                  alt="maritesLiveImg"
                  className="img-fluid tutorial-img"
                />
                <h1 style={{ fontSize: "35px" }}>Get Benefits</h1>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gilas-slide">
                <img
                  src={Images.winPrizes2}
                  alt="maritesLiveImg"
                  className="img-fluid"
                />
                <img
                  src={Images.winPrizes}
                  alt="maritesLiveImg"
                  className="img-fluid tutorial-img"
                />
                <h1 style={{ fontSize: "35px" }}>Win Prizes</h1>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="gilas-slide">
                <img
                  src={Images.joinActivities3}
                  alt="maritesLiveImg"
                  className="img-fluid"
                />
                <img
                  src={Images.joinActivities}
                  alt="totoyLiveImg"
                  className="img-fluid tutorial-img"
                />
                <div className="text-center">
                  <h1>Join Activities</h1>
                </div>
                <Link to="/home" className="mt-3">
                  <Button variant="primary">START</Button>
                </Link>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </main>
    </>
  );
};

export default Tutorial;
