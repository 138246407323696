import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { rightArrow } from "../../../icons/icon";
import { routes } from "../../../helpers/constants";

const SignupStep1 = ({
  formData1,
  formErrors1,
  handleInputChange,
  nextTabHandler,
  maxDate,
}) => {
  return (
    <div className="signup-step-1">
      <h2 className="text-center text-secondary mb-4">Create your Profile</h2>
      <div>
        <Form.Group className="mb-3" controlId="firstName">
          <Form.Control
            type="text"
            placeholder="Enter First Name"
            name="firstName"
            value={formData1.firstName}
            onChange={handleInputChange}
          />
          <div className="form-err">{formErrors1.firstName}</div>
        </Form.Group>
        <Form.Group className="mb-3" controlId="lastName">
          <Form.Control
            type="text"
            placeholder="Enter Last Name"
            name="lastName"
            value={formData1.lastName}
            onChange={handleInputChange}
          />
          <div className="form-err">{formErrors1.lastName}</div>
        </Form.Group>
        <Form.Group className="mb-3" controlId="address">
          <Form.Control
            type="text"
            placeholder="Enter Home Address"
            name="address"
            value={formData1.address}
            onChange={handleInputChange}
          />
          <div className="form-err">{formErrors1.address}</div>
        </Form.Group>
        <Form.Group className="birthdate-container" controlId="birthday">
          <Form.Control
            type="date"
            placeholder="Enter Birthday"
            name="birthday"
            value={formData1.birthday}
            onChange={handleInputChange}
            readOnly={false}
            min="1900-01-01"
            max={maxDate}
            className="birthdate-datepicker"
          />

          <span className="date-place-holder">
            {formData1.birthday ? "" : "Enter Birthdate"}
          </span>
        </Form.Group>
        <div className="form-err">{formErrors1.birthday}</div>
        <div className="sign-up">
          <strong>Already have an account ?</strong>
          <Link to={routes.loginPage}> Sign In</Link>
        </div>
        <button
          className="tc-save login-button mt-5"
          onClick={() => nextTabHandler()}
        >
          Next
          {rightArrow}
        </button>
      </div>
    </div>
  );
};

export default SignupStep1;
