import React, { useEffect } from "react";
import { Images } from "../../images";
import { useNavigate } from "react-router-dom";

const ActivityPreloader = () => {
  const navigate = useNavigate();

  const navigateToNextPage = () => {
    setTimeout(() => {
      navigate("/activityDetails");
    }, 3000);
  };

  useEffect(() => {
    navigateToNextPage();
  }, []);

  return (
    <>
      <div className="flex_wrapper">
        <div>
          <h2 className="fw-bold mb-4">Register now at NCSC</h2>
          <div className="mb-4">
            <img src={Images.ncscLogo} alt="ncsc" className="img-fluid" />
          </div>
          <div className="loader_wrapper">
            <p className="loading_text">loading...</p>
            <div className="loading_bar">
              <div className="progress_bar"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityPreloader;
