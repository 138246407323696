import React from "react";
import ActivtyCard from "../../component/Common/ActivtyCard";
import { Images } from "../../images";

const ActivityDetails = () => {
  return (
    <>
      <main className={`main_content`}>
        <div className="flex_wrapper p-3">
          <ActivtyCard
            points="100"
            Icon={Images.advance}
            title="Buy Advance and get 35% discount"
            button="BUY"
          />
        </div>
      </main>
    </>
  );
};

export default ActivityDetails;
