import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { toast } from "react-toastify";

//Get Boost list
export const getBoostList = createAsyncThunk("getBoostList", async () => {
  try {
    const response = await Axios.get(`/boost/get`, authHeaders());
    return response;
  } catch (error) {
    return error;
  }
});

export const addBoostId = createAsyncThunk("addBoostId", async (data) => {
  try {
    const response = await Axios.post(`/boost/add`, data.data, authHeaders());
    if (response.status === 200 || response.status === 201) {
      toast.success(data.toast, { autoClose: 3000 });
    }
    if (response.status === 202) {
      toast.warn("Boost Already Added", { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
});

const boostSlice = createSlice({
  name: "boost",
  initialState: {
    boostList: [],
    addBoostId: "",
    error: "",
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get Boost List
    builder.addCase(getBoostList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getBoostList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.boostList = action?.payload?.data?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(getBoostList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //Add Boost Id
    builder.addCase(addBoostId.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(addBoostId.fulfilled, (state, action) => {
      state.loader = false;
      state.addBoostId = action.payload;
    });
    builder.addCase(addBoostId.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default boostSlice.reducer;
