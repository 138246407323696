import React from "react";
import PhoneNumberForm from "../SignupComponent/PhoneNumberForm";
import { rightArrow } from "../../../icons/icon";

const LoginForm = ({ onchangeHandler, values, errors, handleSendOtp }) => {
  return (
    <>
      <PhoneNumberForm
        onChange={onchangeHandler}
        values={values}
        errors={errors}
      />
      <button
        className="tc-save login-button mt-5"
        type="submit"
        onClick={(e) => handleSendOtp(e)}
      >
        Login
        {rightArrow}
      </button>
    </>
  );
};

export default LoginForm;
