import React from "react";
import rotateLSGif from "../../../images/rotatePhone.gif";
import rotatePSGif from "../../../images/rotatePhone2.gif";

const RotatePhone = ({ type }) => {
  return (
    <>
      {type === "landscape" ? (
        <div className="game-rotate-phone">
          <img src={rotateLSGif} />
          <h3>Please rotate your phone!</h3>
        </div>
      ) : (
        <div className="game-rotate-phone-second">
          <img src={rotatePSGif} />
          <p>Please rotate your phone!</p>
        </div>
      )}
    </>
  );
};

export default RotatePhone;
